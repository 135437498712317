export const SET_PLAYER_STATE = "SET_PLAYER_STATE"
export const SKIP_FRAME = "SKIP_FRAME"
export const SET_FRAME = "SET_FRAME"
export const SAVE_ORIGINAL_DATA = "saveOrigData"

export interface PlayerStateUpdate {
    playing?: boolean,
    currentFrame?: number,
    nextFrameScheduled?: boolean
}

interface SetPlayerStateAction {
    type: typeof SET_PLAYER_STATE,
    playerState: PlayerStateUpdate
}

interface SkipFrameAction {
    type: typeof SKIP_FRAME,
    skipCnt: number
}

interface SetFrameAction {
    type: typeof SET_FRAME,
    frame: number
}

export interface SaveOriginalGifData {
    type: typeof SAVE_ORIGINAL_DATA,
    data: Uint8Array
}

export type PlayerActionTypes =
    SetPlayerStateAction |
    SetFrameAction |
    SkipFrameAction |
    SaveOriginalGifData