import React, {useEffect, useState} from "react"
import {CardStyle, ImageButton, Loader, SmallLoader} from "../../styles/sharedComponents"
import styled from "styled-components"
import {NavLink} from "react-router-dom"
import {auth, previewsRef, templatesRef, userTemplates} from "../../firebase/firebaseHelper"
import deleteImg from "../../img/delete-24px-red.svg"

const Container = styled(CardStyle)`
  margin: 16px;
  padding: 20px;
  display: inline-block;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const PreviewContainer = styled.div`
  position: relative;
  display: inline-flex;
`

const ButtonsInVideo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;

  &:hover {
    background: rgba(0, 0, 0, 0.36);
  }
`

const Image = styled.img`
  height: 200px;
  object-fit: cover;
`

const ButtonInVideo = styled(NavLink)`
  text-decoration: none;
  background: rgba(55, 171, 243, 0.55);
  color: white;
  padding: 12px 12px 5px 12px;
  border-radius: 5px;
  border: 1px solid white;
  text-transform: uppercase;
  transition: 0.3s;

  ${ButtonsInVideo}:hover & {
    background: rgb(55, 171, 243, 0.8);
  }
`

async function _deleteTemplate(authorId: string, templateId: string, loadTemplates: () => void, setDeleteInProgress: (inProgress: boolean) => void) {
    setDeleteInProgress(true)
    const deleteFromDb = userTemplates(authorId).doc(templateId).delete()
    const deleteFromStorage = previewsRef.child(authorId + "/" + templateId).delete()
    const deleteOrigFromStorage = templatesRef.child(authorId + "/" + templateId).delete()

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const results = await Promise.all([deleteFromDb, deleteFromStorage, deleteOrigFromStorage])
    setDeleteInProgress(false)
    loadTemplates()
}

function _loadTemplate(authorId: string, templateId: string, setUrl: (url: string) => void) {
    const preview = previewsRef.child(authorId + "/" + templateId)

    preview.getDownloadURL().then((url: string) => {
        setUrl(url)
    }).catch((_) => {
        setUrl("")
    })
}

function TemplatePreview(props: { url?: string, authorId: string, templateId: string }) {
    if (props.url == null) {
        return <Loader/>
    } else {
        return <PreviewContainer>
            <Image src={props.url} crossOrigin={"anonymous"}/>
            <ButtonsInVideo>
                {/*/ Note the '-', it's not a mistake*/}
                <ButtonInVideo to={`/gif-creator/${props.authorId}-${props.templateId}`}>
                    Use Template
                </ButtonInVideo>
            </ButtonsInVideo>
        </PreviewContainer>
    }
}

export function Template(props: { authorId: string, templateId: string, title: string, loadTemplates: () => void }) {
    const [url, setUrl] = useState<string | undefined>(undefined)
    const [deleteInProgress, setDeleteInProgress] = useState(false)

    useEffect(() => {
        _loadTemplate(props.authorId, props.templateId, setUrl)
    }, [props.authorId, props.templateId])

    const deleteEnabled = props.authorId === auth.currentUser?.uid

    return <Container>
        <TitleContainer>
            <h2>{props.title}</h2>
            {deleteEnabled && !deleteInProgress &&
            <ImageButton
                onClick={() => _deleteTemplate(props.authorId, props.templateId, props.loadTemplates, setDeleteInProgress)}>
                <img src={deleteImg} alt={""}/>
            </ImageButton>}
            {deleteInProgress && <SmallLoader/>}
        </TitleContainer>

        <TemplatePreview url={url} authorId={props.authorId} templateId={props.templateId}/>
    </Container>
}