import * as React from "react"
import {NavLink} from "react-router-dom"
import styled from "styled-components"
import {User} from "./user/user"
import {useSelector} from "react-redux"
import {RootState} from "../redux/reducers"

const MenuOuterContainer = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 0;
`

const MenuInnerContainer = styled.div`
    display: flex;
    align-items: stretch;
    justify-content: space-evenly;
    width: 50%;
    min-width: 300px;
`


const NavigationLink = styled(NavLink)`
    line-height: 2.5em;
    
    text-align: center;
    color: ${props => props.theme.dark};
    align-items: center;
    text-decoration: none;
    margin: 5px;
    text-transform: uppercase;
    transition: 0.3s;
    flex-grow: 1;
    
    
    &:hover {
        color: ${props => props.theme.dark};
    }
    
    &.active {
        color: ${props => props.theme.accentDark};
        box-shadow: inset 0px -2px 0px 0px ${props => props.theme.dark};
    }`

const Logo = styled.a`
  font-family: 'Impact',serif;
  color: white;
  text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
  text-decoration: none;
  font-size: 2.1em;
  margin: auto 30px;
`

interface IProps {

}

function Menu(props: IProps) {

    const userLoggedIn = useSelector((root: RootState) => root.user.user)

    return (
        <React.Fragment>
            <MenuOuterContainer>
                <Logo href={'https://gifmemes.io'}>Gifmemes.io</Logo>
                <MenuInnerContainer>
                    {/*<SortMenuContainer>*/}
                    {/*    <SortLink exact to="/" activeClassName="active">Hot</SortLink>*/}
                    {/*    <SortLink to="/top" activeClassName="active">Top</SortLink>*/}
                    {/*    <SortLink to="/new" activeClassName="active">New</SortLink>*/}
                    {/*</SortMenuContainer>*/}

                    {userLoggedIn && <NavigationLink to="/my-memes" activeClassName="active">My&nbsp;Memes</NavigationLink>}
                    <NavigationLink to="/templates" activeClassName="active">Templates</NavigationLink>
                    <NavigationLink to="/gif-creator" activeClassName="active">Gif&nbsp;creator</NavigationLink>
                    <NavigationLink to={"/tutorial"}>
                        Tutorial
                    </NavigationLink>
                </MenuInnerContainer>
                <User/>
            </MenuOuterContainer>
        </React.Fragment>
    )
}

export default Menu