import {
    CommonActionTypes,
    GO_BACK_IN_HISTORY,
    HANDLE_MOTION_TRACK_RESULT,
    SET_ACTIVE,
    TIMELINE_DRAG,
    UPLOAD_GIF
} from "./commonTypes"
import {UploadState} from "../reducers/editor/types"
import {DecodedFrame} from "../../components/editor/videoManipulator/types"
import {VideoInfo} from "../reducers/player/types"
import {MotionTrackingResult} from "../../components/editor/videoManipulator/motionTracking/motionTracker"

export function handleDrag(frame: number): CommonActionTypes {
    return {
        type: TIMELINE_DRAG,
        frame
    }
}

export function uploadGif(uploadState: UploadState, videoInfo?: VideoInfo, decodedFrames?: DecodedFrame[]): CommonActionTypes {
    return {
        type: UPLOAD_GIF,
        state: uploadState,
        videoInfo,
        decodedFrames
    }
}

export function setActive(id?: number): CommonActionTypes {
    return {
        type: SET_ACTIVE,
        id
    }
}

export function goBackInHistory(): CommonActionTypes {
    return {
        type: GO_BACK_IN_HISTORY
    }
}

export interface HandleMotionTrackResult {
    type: typeof HANDLE_MOTION_TRACK_RESULT
    result: MotionTrackingResult
}