import {PlayerState} from "./types"
import {PlayerActionTypes, SAVE_ORIGINAL_DATA, SET_FRAME, SET_PLAYER_STATE, SKIP_FRAME} from "../../actions/playerTypes"
import {
    CommonActionTypes,
    GO_BACK_IN_HISTORY,
    HANDLE_MOTION_TRACK_RESULT,
    SET_ACTIVE,
    TIMELINE_DRAG,
    UPLOAD_GIF
} from "../../actions/commonTypes"
import {RootState} from "../index"
import {isEditVisible} from "../../../components/editor/videoManipulator/editorCanvasHelpers"
import {getEditIdxById} from "../../../utils/StateHelpers"


export const initialPlayerState: PlayerState = {
    playing: false,
    currentFrame: 0,
    nextFrameScheduled: false,
    videoInfo: {
        width: 0,
        height: 0,
        frameCnt: 0
    }
}

function playerReducer(rootState: RootState, action: PlayerActionTypes | CommonActionTypes): PlayerState {
    const state = rootState.player

    switch (action.type) {
        case SET_PLAYER_STATE: {
            let frame
            const newFrame = action.playerState.currentFrame
            if (newFrame === undefined) {
                frame = state.currentFrame
            } else if (newFrame > rootState.editor.endFrame) {
                frame = rootState.editor.startFrame
            } else {
                frame = newFrame
            }

            return {
                ...state,
                ...action.playerState,
                currentFrame: frame
            }
        }

        case HANDLE_MOTION_TRACK_RESULT:
            return {
                ...state,
                currentFrame: state.currentFrame + 1
            }

        case SKIP_FRAME:
            let newFrame = state.currentFrame + action.skipCnt
            if (newFrame > rootState.editor.endFrame) {
                newFrame = rootState.editor.startFrame
            } else if (newFrame < rootState.editor.startFrame) {
                newFrame = rootState.editor.endFrame
            }

            return {
                ...state,
                currentFrame: newFrame
            }
        // Dragging also sets the current frame
        case TIMELINE_DRAG:
        case SET_FRAME:
            let frame = action.frame

            if (action.frame < 0) {
                frame = 0
            } else if (frame >= state.videoInfo.frameCnt) {
                frame = state.videoInfo.frameCnt - 1
            }

            return {
                ...state,
                currentFrame: frame
            }
        case UPLOAD_GIF: {
            return {
                ...initialPlayerState,
                videoInfo: action.videoInfo ?? initialPlayerState.videoInfo,
                decodedFrames: action.decodedFrames
            }
        }
        case SAVE_ORIGINAL_DATA: {
            return {
                ...state,
                originalGif: action.data
            }
        }

        case SET_ACTIVE: {
            if (!action.id) return state

            const editIdx = getEditIdxById(rootState.editor.edits, action.id)
            const edit = rootState.editor.edits[editIdx]
            if (edit == null || isEditVisible(edit, rootState.player.currentFrame)) {
                return state
            }

            return {
                ...state,
                currentFrame: state.currentFrame < edit.startFrame ? edit.startFrame : edit.endFrame,
            }
        }

        case GO_BACK_IN_HISTORY: {
            if (rootState.history.history.length < 1) return state

            return {
                ...state,
                currentFrame: rootState.history.history[0].frame
            }
        }

        default:
            return state
    }
}

export default playerReducer