import {Loader} from "../../styles/sharedComponents"
import * as React from "react"
import styled from "styled-components"
import {useSelector} from "react-redux"
import {RootState} from "../../redux/reducers"
import {useEffect, useState} from "react"

const Note = styled.div`
    width: 150px;
    text-align: center;
    margin-top: 20px;
`

export function LoadingWithNote(props: {}) {
    const note = useSelector((state: RootState) => state.editor.progress.note)
    const [additionalNote, setAdditionalNote] = useState<string | undefined>(undefined)

    useEffect(() => {
        const timeout = setTimeout(() => {
            setAdditionalNote('It might take a while for larger files, hold on...')
        }, 5000)

        return () => {
            clearTimeout(timeout)
        }
    })

    return (
        <div>
            <Loader/>
            <Note>{note}</Note>
            {additionalNote !== null && <Note>{additionalNote}</Note>}
        </div>
    )
}