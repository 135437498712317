import * as React from "react"
import {FunctionComponent, useState} from "react"
import {Background, Container} from "../DialogComponents"
import styled from "styled-components"
import {Line} from "../editPanel/EditPanel"
import {Button, Failure, Success} from "../../../styles/sharedComponents"
import {useSelector} from "react-redux"
import {RootState} from "../../../redux/reducers"
import {UploadState} from "../../../redux/reducers/editor/types"
import {LoadingWithNote} from "../LoadingWithNote"

export const TextInput = styled.input`
  font-size: 1.5em;
  width: 150px;
`

const UploadButton = styled(Button)`
  margin: 12px;
  padding: 8px 16px;
`

const Divider = styled.div`
  margin: 12px;
`

export const UploadDialog: FunctionComponent<{
    uploadFile: () => void,
    uploadUrl: (url: string) => void,
    showUploadDialog: (show: boolean) => void
}> = ({
          uploadFile,
          uploadUrl,
          showUploadDialog
      }) => {
    const [url, setUrl] = useState("")
    const uploadState = useSelector((state: RootState) => state.editor.progress.uploadProgress)

    const states = {
        [UploadState.UPLOADED]: <Success>Uploaded successfully!</Success>,
        [UploadState.FAILED]: <Failure>Upload failed. <span role={"img"} aria-label={"cry emoji"}>😥😱</span> Some websites do not allow downloading gifs directly. Try
            downloading the file first and uploading it from your computer.</Failure>,
        [UploadState.UPLOADING]: <LoadingWithNote/>,
        [UploadState.NONE]: <React.Fragment>
            <Line>
                <label>Url:</label>
                <TextInput type="text" value={url} onChange={e => setUrl(e.target.value)}/>
                <UploadButton onClick={() => uploadUrl(url)}>Upload GIF</UploadButton>
            </Line>
            <Divider>OR</Divider>
            <UploadButton onClick={() => uploadFile()}>Choose GIF from your computer</UploadButton>
        </React.Fragment>
    }

    return <Background onClick={() => {
        if (uploadState !== UploadState.UPLOADING) {
            showUploadDialog(false)
        }
    }}>
        <Container onClick={(e) => e.stopPropagation()}>
            {states[uploadState]}
        </Container>
    </Background>
}