import styled from "styled-components"
import {exportedRef, FirebaseReference} from "../../firebase/firebaseHelper"
import React, {useEffect, useState} from "react"
import {useSelector} from "react-redux"
import {RootState} from "../../redux/reducers"
import {Meme} from "./meme"
import { Redirect } from "react-router-dom"


const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`

const NoItems = styled.div`
  margin-top: 60px;
`

async function _loadMemes(setMemes: (memes: FirebaseReference[]) => void, uid: string) {
    if (uid == null) return
    console.log("Here")
    const refs = await exportedRef.child(uid).listAll()
    console.log("here: " + refs.items)
    setMemes(refs.items)
}

export function Memes(props: {}) {
    const [memes, setMemes] = useState<FirebaseReference[]>([])
    const userUid = useSelector((rootState: RootState) => rootState.user?.user?.uid)!

    useEffect(() => {
        _loadMemes(setMemes, userUid).then()
    }, [userUid])

    function _deleteMeme(reference: FirebaseReference) {
        reference.delete()
        setMemes(memes.filter(meme => meme.name !== reference.name))
    }

    const items = memes.map((item) => <Meme reference={item} deleteMeme={() => _deleteMeme(item)} key={item.fullPath}/>)


    if (userUid == null) {
        return <Redirect to={'/'}/>;
    }

    return <Container>
        {items}
        {items.length === 0 && <NoItems>Nothing here, you can create memes in the GIF CREATOR.</NoItems>}
    </Container>
}