import styled from "styled-components"
import pin from "../../../../../img/pin.svg"
import * as React from "react"
import {useRef} from "react"
import {useDispatch, useSelector} from "react-redux"
import {TimelineDragType} from "../../../../../redux/reducers/editor/types"
import {setFrame} from "../../../../../redux/actions/playerActions"
import {RootState} from "../../../../../redux/reducers"

const FrameIndicatorContainer = styled.div<{ expanded: boolean, column: number }>`
  flex-grow: 1;
  justify-self: stretch;
  grid-row-start: 2;
  grid-column: ${props => props.column};
  user-select: none;
  display: flex;
  height: 16px;
  align-content: center;
  cursor: pointer;
  min-width: 2px;
  position: relative;
  z-index: 2;
`

const FrameIndicator = styled.div`
  background: black;
  width: 1px;
  height: 20px;
  max-height: 12px;
  transition: max-height 0.15s;
  align-self: center;

  ${FrameIndicatorContainer}:hover & {
    max-height: 20px;
    width: 1px;
  }
`

const Pin = styled.div`
  background-image: url(${pin});
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  height: 24px;
  position: absolute;
  left: -11px;
  grid-row-start: 1;
  cursor: ew-resize;
  z-index: 3;
  top: -16px;
`

const FrameCnt = styled.div<{ active: boolean }>`
  color: rgba(255, 255, 255, 0);
  position: absolute;
  top: -16px;
  left: -10px;
  width: 20px;
  text-align: center;
  transition: 0.3s;
  font-size: 7px;
  pointer-events: none;

  ${props => props.active ? `
    font-size: 13px;
    top: -24px;
    color: #9a9a9a;
  ` : undefined}
  ${FrameIndicatorContainer}:hover & {
    font-size: 13px;
    top: -24px;
    color: #9a9a9a;
  }
`

const QuickPreview = styled.canvas`
  position: absolute;
  top: -60px;
`


export function ProgressIndicator(
    props: {
        expanded: boolean
        onMouseDown: (event: React.MouseEvent, dragType: TimelineDragType, editId?: number) => void
    }
) {
    const pin = useRef(null)
    const quickPreview = useRef<HTMLCanvasElement>(null)
    const dispatch = useDispatch()
    const frameCnt = useSelector((state: RootState) => state.player.videoInfo.frameCnt)
    const currentFrame = useSelector((state: RootState) => state.player.currentFrame)
    const decodedFrames = useSelector((state: RootState) => state.player.decodedFrames)
    const videoInfo = useSelector((state: RootState) => state.player.videoInfo)

    const createPreview = (idx: number) => {
        const canvas =  quickPreview.current
        const ctx = quickPreview.current?.getContext("2d")
        if (!ctx || !decodedFrames || !canvas) return
        ctx.drawImage(decodedFrames[idx].buffer, 0, 0, canvas.width, canvas.height)
        quickPreview.current?.setAttribute("style", `grid-column: ${idx + 2}; box-shadow: 2px 2px 13px 0 rgb(144, 144, 144);`)
    }

    const hidePreview  = () => {
        quickPreview.current?.setAttribute("style", "visibility: hidden")
    }

    const frames = []
    for (let i = 0; i < frameCnt; i++) {
        frames.push(
            <FrameIndicatorContainer
                onMouseOver={() => createPreview(i)}
                onMouseLeave={() => hidePreview()}
                column={i + 2}
                key={i}
                expanded={props.expanded}
                onClick={() => {
                    dispatch(setFrame(i))
                }}
            >
                <FrameIndicator/>
                <FrameCnt active={(i + 1) % 5 === 0}>
                    {i + 1}
                </FrameCnt>
            </FrameIndicatorContainer>
        )
    }

    const previewHeight = 60
    const previewWidth = (60 / videoInfo.height) * videoInfo.width

    return (
        <React.Fragment>
            <QuickPreview ref={quickPreview} width={previewWidth} height={previewHeight}/>
            <Pin
                onDragStart={() => {
                    return false
                }}
                ref={pin}
                onMouseDown={(e: React.MouseEvent) => props.onMouseDown(e, TimelineDragType.PIN)}
                style={{gridColumnStart: `${currentFrame + 2}`}}
            />
            {frames}
        </React.Fragment>
    )
}