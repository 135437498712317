import firebase from "firebase/app"

export const LOGIN = "LOGIN"
export const LOGOUT = "LOGOUT"


interface LoginAction {
    type: typeof LOGIN,
    user: firebase.User
}

interface LogoutAction {
    type: typeof LOGOUT,
}

export type UserActionTypes =
    LoginAction |
    LogoutAction