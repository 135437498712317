import {Edit, ExportState, MoveWithAnchor, TextEdit, TimelineDragType} from "../reducers/editor/types"
import {
    ADD_IMAGE,
    ADD_MOVE_POINT,
    ADD_TEXT,
    ADD_TITLE_SPACE,
    CENTER,
    CHANGE_EXPORT_STATE,
    CHANGE_ORDER,
    CHANGE_POSITION, CHANGE_PROGRESS_NOTE,
    DELETE_EDIT,
    DRAG,
    EDIT_TEXT,
    EditorActionTypes,
    ExportType,
    REMOVE_ALL_MOVE_POINTS,
    REMOVE_MOVE_POINT,
    SAVE_DRAG_TO_HISTORY,
    SAVE_PREVIEW,
    SET_DRAGGING,
    SET_MOTION_TRACK_ACTIVE,
    SET_MOTION_TRACKING_IN_PROGRESS,
    SET_TEMPLATE,
    TOGGLE_ANIMATE,
    TOGGLE_AUTO_TRACK_ACTION,
    TOGGLE_MOTION_TRACKING,
    TOGGLE_WATERMARK
} from "./editorActionTypes"
import {AppThunk} from "./other"
import {DragType} from "../../components/editor/videoManipulator/types"
import {motionTrack as computeMotionTrack} from "../../components/editor/videoManipulator/motionTracking/motionTracker"
import {anchorToMoves, getCurrentSizeAndPosition, getEditIdxById} from "../../utils/StateHelpers"
import {cutNewAnchor} from "../../components/editor/videoManipulator/motionTracking/trackingFunctions"
import {HANDLE_MOTION_TRACK_RESULT} from "./commonTypes"

export function changeProgressNote(note: string) {
    return {
        type: CHANGE_PROGRESS_NOTE,
        note
    }
}

export function addText(): EditorActionTypes {
    return {
        type: ADD_TEXT,
    }
}

export function addImage(img: string, width: number, height: number): EditorActionTypes {
    return {
        type: ADD_IMAGE,
        img,
        width,
        height
    }
}

export function editText(params: Partial<TextEdit & {textSize: number}>): EditorActionTypes {
    return {
        type: EDIT_TEXT,
        params
    }
}

export function changePosition(x: number, y: number): AppThunk {
    return (dispatch, getState) => {
        dispatch({
            type: CHANGE_POSITION,
            x, y,
            frameIdx: getState().player.currentFrame
        })
    }
}

export function drag(type: DragType, x: number, y: number, frameIdx: number): EditorActionTypes {
    return {
        type: DRAG,
        x: Math.floor(x),
        y: Math.floor(y),
        dragType: type,
        frameIdx
    }
}


export function removeMovePoint(idx: number): EditorActionTypes {
    return {
        type: REMOVE_MOVE_POINT,
        frameIdx: idx
    }
}

export function removeAllMovePoints(): EditorActionTypes {
    return {
        type: REMOVE_ALL_MOVE_POINTS
    }
}

export function toggleAddMovePoints(): EditorActionTypes {
    return {
        type: TOGGLE_ANIMATE
    }
}

export function toggleMotionTracking(): EditorActionTypes {
    return {
        type: TOGGLE_MOTION_TRACKING
    }
}

function setMotionTrackingInProgress(): EditorActionTypes {
    return {
        type: SET_MOTION_TRACKING_IN_PROGRESS
    }
}

export function initializeMotionTracking(): AppThunk {
    return async (dispatch, getState) => {
        const rootState = getState()
        if (rootState.player.currentFrame === rootState.player.videoInfo.frameCnt - 1) {
            return
        }

        dispatch(setMotionTrackingInProgress())

        const state = rootState.editor
        const currentFrame = rootState.player.currentFrame
        const activeEditIdx = getEditIdxById(state.edits, state.active!)
        const currentEdit = state.edits[activeEditIdx]

        const oldAnchor = getCurrentSizeAndPosition(anchorToMoves(currentEdit.moves as MoveWithAnchor[]), rootState.player.currentFrame)

        const width = rootState.player.videoInfo.width
        const currentFrameData = new ImageData(rootState.player.decodedFrames![currentFrame].pixels, width)
        const nextFrameData = new ImageData(rootState.player.decodedFrames![currentFrame + 1].pixels, width)

        const oldAnchorData = cutNewAnchor(currentFrameData, oldAnchor.startX, oldAnchor.startY, oldAnchor.width, oldAnchor.height)
        console.log("motion track:", oldAnchor.startX, oldAnchor.startY)
        const result = await computeMotionTrack(nextFrameData, oldAnchorData, oldAnchor.startX, oldAnchor.startY)

        dispatch({
            type: HANDLE_MOTION_TRACK_RESULT,
            result
        })
    }
}

export function addMovePoint(): EditorActionTypes {
    return {
        type: ADD_MOVE_POINT,
    }
}

export function changeOrder(id: number, up: boolean): EditorActionTypes {
    return {
        type: CHANGE_ORDER,
        id,
        up
    }
}

export function deleteEdit(id: number): EditorActionTypes {
    return {
        type: DELETE_EDIT,
        id
    }
}

export function setMotionTrackActive(): EditorActionTypes {
    return {
        type: SET_MOTION_TRACK_ACTIVE
    }
}

export function toggleAutoTrack(): EditorActionTypes {
    return {
        type: TOGGLE_AUTO_TRACK_ACTION
    }
}

export function setDragging(dragType?: TimelineDragType, editId?: number): EditorActionTypes {
    return {
        type: SET_DRAGGING,
        dragType,
        editId
    }
}

export function savePreviewAction(blob: Blob) {
    return {
        type: SAVE_PREVIEW,
        data: blob
    }
}

export function changeExportState(exportState: ExportState, exportType: ExportType, blob?: Blob): EditorActionTypes {
    return {
        type: CHANGE_EXPORT_STATE,
        state: exportState,
        exportType,
        blob
    }
}

export function center(): EditorActionTypes {
    return {
        type: CENTER
    }
}

export function toggleWatermark(): EditorActionTypes {
    return {
        type: TOGGLE_WATERMARK
    }
}

export function setTemplate(edits: Edit[]): EditorActionTypes {
    return {
        type: SET_TEMPLATE,
        edits
    }
}

export function addTitleSpace(height: number): EditorActionTypes {
    return {
        type: ADD_TITLE_SPACE,
        height
    }
}

export function saveHistory(): EditorActionTypes {
    return {
        type: SAVE_DRAG_TO_HISTORY
    }
}