import * as React from "react"
import {useState} from "react"
import styled from "styled-components"
import {useDispatch, useSelector} from "react-redux"
import {RootState} from "../../../../redux/reducers"
import {ExportState} from "../../../../redux/reducers/editor/types"
import {Dispatch} from "redux"
import {EditorActionTypes, ExportType} from "../../../../redux/actions/editorActionTypes"
import {changeExportState} from "../../../../redux/actions/editorActions"
import {Button, SmallLoader} from "../../../../styles/sharedComponents"
import downloadImg from "../../../../img/cloud_download-24px.svg"
import shareImg from "../../../../img/share-24px.svg"
import {Background, Container} from "../../DialogComponents"
import {auth, exportedRef} from "../../../../firebase/firebaseHelper"
import {PublishTemplate} from "./PublishTemplate"
import {getDetailUrl, makeId} from "../../../../utils/CommonHelper"
import {LoadingWithNote} from "../../LoadingWithNote"

const DownloadButton = styled(Button)`
   margin: 12px;
  font-size: 0.7em;
`

const UploadButton = styled(Button)`
  margin: 12px;
  font-size: 0.7em;
`


export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`

const UrlHolder = styled.input`
  width: 200px;
  margin: auto;
`

export const Title = styled.span`
  font-size: 1.3em;
  margin-top: 1em;
`

export const Label = styled.label`
  padding: 8px;
  & > input {
    margin-left: 8px;
  }
`

export enum UploadingState {
    NONE,
    UPLOADING,
    DONE
}

function _upload(blob: Blob, setUploading: (uploading: ShareUrlState) => void) {
    if (!auth.currentUser) return

    const id = makeId(6)
    setUploading({ state: UploadingState.UPLOADING } )

    const imgRef = exportedRef.child(auth.currentUser.uid).child(id)
    imgRef.put(blob, {cacheControl: "public,max-age=604800,immutable"}).then(function (snapshot) {
        setUploading({
            state: UploadingState.DONE,
            url: getDetailUrl(auth.currentUser!.uid, id)
        })
    }).catch(() => {
        setUploading({ state: UploadingState.NONE})
    })
}

interface ShareUrlState {
    state: UploadingState,
    url?: string
}
export function ExportDialog(props: {}) {
    const [exportUploadState, setExportUploading] = useState<ShareUrlState>({ state: UploadingState.NONE})

    const dispatch = useDispatch<Dispatch<EditorActionTypes>>()
    const exportedGif = useSelector((state: RootState) => state.editor.exported.exportedGif)
    const exportedVideo = useSelector((state: RootState) => state.editor.exported.exportedVideo)

    const loading = useSelector(
        (state: RootState) =>
            state.editor.progress.exportProgress.gif === ExportState.RUNNING ||
            state.editor.progress.exportProgress.video === ExportState.RUNNING
    )
    const userLoggedIn = useSelector((state: RootState) => state.user.user)

    function getUrl(blob: Blob): string {
        const urlCreator = window.URL || window.webkitURL
        return  urlCreator.createObjectURL(blob)
    }

    const videoURL = exportedVideo !== undefined ? getUrl(exportedVideo) : undefined
    const gifURL = exportedGif !== undefined ? getUrl(exportedGif) : undefined

    let preview;
    if (videoURL !== undefined) {
        preview = <video controls loop autoPlay>
            <source src={videoURL}/>
        </video>
    } else {
        preview = <img src={gifURL} alt={""}/>
    }

    const type = exportedVideo?.type?.replace('video/', '')

    const content = loading ?
        <LoadingWithNote/> :
        <React.Fragment>
            {preview}
            <ButtonsContainer>
                {videoURL !== undefined && <a href={videoURL} download={"meme." + type} style={{textDecoration: "none"}}>
                    <DownloadButton>
                        <img src={downloadImg} alt="download"/>Download {type}
                    </DownloadButton>
                </a>}
                <a href={gifURL} download={"meme.gif"} style={{textDecoration: "none"}}>
                    <DownloadButton>
                        <img src={downloadImg} alt="download"/>Download GIF
                    </DownloadButton>
                </a>
                {userLoggedIn && exportUploadState.state === UploadingState.NONE &&
                <UploadButton onClick={() => _upload(exportedGif!, setExportUploading)}>
                    <img src={shareImg} alt="upload"/>Upload
                    &
                    share</UploadButton>}
                {exportUploadState.state === UploadingState.UPLOADING &&
                <SmallLoader/>
                }
                {exportUploadState.state === UploadingState.DONE &&
                <UrlHolder type="text" value={exportUploadState.url} readOnly={true}/>
                }
            </ButtonsContainer>
            {userLoggedIn &&
            <PublishTemplate/>
            }

        </React.Fragment>


    return (
        <Background onClick={() => {
            if (!loading && window.confirm("Are you sure you want to close export dialog? Gif will need to be exported again.")) {
                dispatch(changeExportState(ExportState.NONE, ExportType.GIF))
                dispatch(changeExportState(ExportState.NONE, ExportType.WEBM))
            }
        }}>
            <Container onClick={(e) => e.stopPropagation()}>
                {content}
            </Container>
        </Background>
    )
}