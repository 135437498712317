import * as React from "react"
import styled from "styled-components"
import {useDispatch, useSelector} from "react-redux"
import {getActiveEdit} from "../../../utils/StateHelpers"
import {CardStyle} from "../../../styles/sharedComponents"
import {EditType} from "../../../redux/reducers/editor/types"
import {RootState} from "../../../redux/reducers"
import {ImageEditEditor} from "./ImageEditEditor"
import {TextEditEditor} from "./TextEdit"
import {toggleAddMovePoints} from "../../../redux/actions/editorActions"

const EmptyWrapper = styled.div<{showBorder: boolean}>`
  margin-top: 16px;
  margin-left: 12px;
  margin-right: 12px;
  border: ${props => props.showBorder ? '1px dashed grey' : ''};
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
  width: 610px;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const EditHint = styled.div``

const CardContainer = styled(CardStyle)`
  padding: 16px;
  box-sizing: border-box;
  z-index: 9;
  position:relative;
  align-self: start;
  user-select: none;
`

export const Line = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 0;
  color: ${props => props.theme.accentDark};
  
  & label {
    margin-right: 6px;
    display: flex;
    align-items: center;
  }
`
export const TextInput = styled.input`
  font-size: 1.5em;
`

export function EditPanel(props: {}) {
    const edit = useSelector((state: RootState) => getActiveEdit(state.editor))
    const dispatch = useDispatch()
    let component
    if (edit !== null) {
        let editBox;
        const moves = edit.moves

        function toggleAnimate() {
            if (!Array.isArray(moves) ||
                moves.length === 1 ||
                window.confirm("All your animated positions will be deleted. Do you want to proceed?")
            ) {
                dispatch(toggleAddMovePoints())
            }
        }


        switch (edit.type) {
            case EditType.TEXT:
                editBox = <TextEditEditor toggleAnimate={toggleAnimate}/>
                break
            case EditType.IMAGE:
                editBox = <ImageEditEditor toggleAnimate={toggleAnimate}/>
                break
        }

        component = <CardContainer>{editBox}</CardContainer>

    }

    return (
        <EmptyWrapper showBorder={component == null}>
            {component == null && <EditHint>Select or create an edit.</EditHint>}
            {component != null && component}
        </EmptyWrapper>
    )
}