import {combinedSearch2} from "./trackingFunctions"

export interface MotionTrackingResult {
    anchorX: number,
    anchorY: number,
    score: number
}

const myWorker = window.Worker ?  new Worker("./motionTracking.worker", {type: "module"}) : undefined


export function motionTrack(
    imageData: ImageData,
    oldAnchor: ImageData,
    prevX: number,
    prevY: number): Promise<MotionTrackingResult> {

    return new Promise<MotionTrackingResult>((resolve, reject) => {
        let testCtx: CanvasRenderingContext2D | null | undefined = undefined
        const TEST = false

        if (TEST) {
            let testingCanvas = document.getElementById("asdfCanvas") as HTMLCanvasElement

            if (testingCanvas == null) {
                const asdf = document.getElementById("asdf")
                testingCanvas = document.createElement("canvas")
                asdf?.appendChild(testingCanvas)
            }

            testingCanvas.id = "asdfCanvas"
            testingCanvas.width = imageData.width
            testingCanvas.height = imageData.height
            testCtx = testingCanvas.getContext("2d")
            testCtx!.fillStyle = "rgb(0,0,0)"
            testCtx?.fillRect(0, 0, imageData.width, imageData.height)
            // testCtx.putImageData(oldAnchor, 0, 0)
            // testCtx.fillStyle = "rgb(255,255,255)"
            // testCtx.fillRect(prevX, prevY, 10, 10)
        }

        if (myWorker !== undefined && !TEST) {
            // TODO add transferable
            myWorker.postMessage({imageData, oldAnchor, prevX, prevY, checkSize: 100, checkStep: 3})
            console.log("Message posted to worker")
            myWorker.onmessage = (e) => {
                console.log("Message received from worker" + e.data)
                resolve(e.data as MotionTrackingResult)
            }
        } else {
            //console.log("Your browser doesn't support web workers.")
            //const res = checkAllNearest(     testCtx!,imageData, oldAnchor, prevX, prevY, 100, 1)
            //const res = greedySearch(testCtx, imageData, oldAnchor, prevX, prevY, [{}], 100)
            //return combinedSearch(testCtx, imageData, oldAnchor, prevX, prevY)
            const res = combinedSearch2(imageData, oldAnchor, prevX,  prevY, 100, 2, testCtx!)
            //drawAnchorImageWithScore(res.anchorX, res.anchorY, oldAnchor.width, oldAnchor.height, imageData, oldAnchor, prevX, prevY)

            // give canvas time to re-render, most browsers support webworkers anyway, so this is a reasonable hack
            setTimeout(() => {
                resolve(res)
            }, 10)
        }
    })
}
