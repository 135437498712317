import {RootState} from "../index"
import {CommonActionTypes} from "../../actions/commonTypes"
import {UserState} from "./types"
import {LOGIN, LOGOUT, UserActionTypes} from "../../actions/userTypes"



export const initialUserState: UserState = {
    loading: true,
    user: undefined
}

export function userReducer(rootState: RootState, action: UserActionTypes | CommonActionTypes): UserState {
    const state = rootState.user

    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                loading: false,
                user: action.user
            }
        case LOGOUT:
            return {
                ...state,
                loading: false,
                user: undefined
            }
    }

    return state
}