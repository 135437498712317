import React, {useEffect, useState} from "react"
import {CardStyle, ImageButton} from "../../styles/sharedComponents"
import styled from "styled-components"
import {auth, FirebaseReference} from "../../firebase/firebaseHelper"
import deleteImg from "../../img/delete-24px-red.svg"
import {getDetailUrl} from "../../utils/CommonHelper"

const Card = styled(CardStyle)`
  margin: 16px;
  padding: 16px;
  flex-flow: column;
  display: flex;
`

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export function Meme(props: { reference: FirebaseReference, deleteMeme: () => void }) {
    const [url, setUrl] = useState()
    useEffect(() => {
        props.reference.getDownloadURL().then(r => setUrl(r))
    }, [props.reference])

    if (!auth.currentUser) throw Error('User is not logged in')
    const detailUrl = getDetailUrl(auth.currentUser.uid, props.reference.name)

    return <Card>
        <Controls>
            <input type={"text"} value={detailUrl}/>
            <ImageButton
                onClick={props.deleteMeme}>
                <img src={deleteImg} alt={""}/>
            </ImageButton>
        </Controls>
        <img src={url} crossOrigin={"anonymous"} alt={""}/>
    </Card>
}