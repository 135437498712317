import {UploadState} from "../reducers/editor/types"
import {DecodedFrame} from "../../components/editor/videoManipulator/types"
import {VideoInfo} from "../reducers/player/types"
import {MotionTrackingResult} from "../../components/editor/videoManipulator/motionTracking/motionTracker"

export const TIMELINE_DRAG = "TIMELINE_DRAG"
export const UPLOAD_GIF = "UPLOAD_GIF"
export const SET_ACTIVE = "SET_ACTIVE"
export const GO_BACK_IN_HISTORY = "GO_BACK_IN_HISTORY"
export const HANDLE_MOTION_TRACK_RESULT = "HANDLE_MOTION_TRACK_RESULT"

interface HandleDragAction {
    type: typeof TIMELINE_DRAG,
    frame: number
}

interface UploadGifAction {
    type: typeof UPLOAD_GIF,
    state: UploadState,
    videoInfo?: VideoInfo,
    decodedFrames?: DecodedFrame[],
}

interface SetActiveAction {
    type: typeof SET_ACTIVE,
    id?: number
}

interface GoBackInHistoryAction {
    type: typeof GO_BACK_IN_HISTORY
}

interface HandleMotionTrackResult {
    type: typeof HANDLE_MOTION_TRACK_RESULT
    result: MotionTrackingResult
}

export type CommonActionTypes =
    HandleDragAction |
    UploadGifAction |
    SetActiveAction |
    GoBackInHistoryAction |
    HandleMotionTrackResult