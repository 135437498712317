import * as React from "react"
import {useRef} from "react"
import styled from "styled-components"
import {useDispatch, useSelector} from "react-redux"
import {setDragging} from "../../../../redux/actions/editorActions"
import {handleDrag} from "../../../../redux/actions/commonActions"
import {RootState} from "../../../../redux/reducers"
import {TimelineDragType} from "../../../../redux/reducers/editor/types"
import {VideoLength} from "./VideoLength/VideoLength"
import {ProgressIndicator} from "./ProgressIndicator/ProgressIndicator"
import {EditTimeline} from "./EditTimeline/EditTimeline"
import Info from "./ProgressIndicator/Info"

interface TimelineProps {
    frameCnt: number
}

const TimelineContainer = styled.div<TimelineProps>`
  margin-top: 16px;
  position: relative;
  display: grid;
  // Add +1 for the left info box
  grid-template-columns: repeat(${(props) => props.frameCnt + 1}, auto);
  grid-template-rows: 32px [row-indicator] 16px [row-line];
`

function getOffset(el: HTMLElement) {
    const rect = el.getBoundingClientRect()
    return {
        left: rect.left + window.scrollX,
        top: rect.top + window.scrollY
    }
}

export function Timeline(props: { expanded: boolean }) {
    const timeline = useRef<HTMLDivElement>(null)
    const infoBox = useRef<HTMLDivElement>(null)
    const dispatch = useDispatch()

    const frameCnt = useSelector((state: RootState) => state.player.videoInfo.frameCnt)
    const edits = useSelector((state: RootState) => state.editor.edits)

    function onMouseDown(event: React.MouseEvent, dragType: TimelineDragType, editId?: number) {
        document.addEventListener("mousemove", onMouseMove)
        document.addEventListener("mouseup", onMouseUp)
        document.body.style.cursor = "ew-drag"
        dispatch(setDragging(dragType, editId))
        _drag(event as unknown as MouseEvent)
    }

    function onMouseUp() {
        document.removeEventListener("mousemove", onMouseMove)
        document.removeEventListener("mouseup", onMouseUp)
        document.body.style.cursor = ""
        dispatch(setDragging(undefined, undefined))
    }

    function onMouseMove(event: MouseEvent) {
        _drag(event)
    }

    function _drag(event: MouseEvent) {
        const leftWidth = infoBox.current?.clientWidth ?? 0
        const indicatorWidth = ((timeline.current!).scrollWidth - leftWidth) / frameCnt
        dispatch(handleDrag(Math.floor((event.pageX - (getOffset(timeline.current!).left + leftWidth) + 8) / indicatorWidth)))
    }


    const editsTimelines = edits.map((edit, idx) => (
        <EditTimeline
            edit={edit}
            idx={idx}
            key={edit.id}
            onMouseDown={onMouseDown}
        />))

    return (
        <TimelineContainer
            ref={timeline}
            frameCnt={frameCnt}
            id="timelineContainer">
            <VideoLength
                onMouseDown={onMouseDown}
            />
            <div ref={infoBox}>
                <Info/>
            </div>
            <ProgressIndicator
                expanded={props.expanded}
                onMouseDown={onMouseDown}
            />

            {editsTimelines}
        </TimelineContainer>
    )
}