import * as React from "react"
import {RefObject} from "react"
import * as gm from "./videoManipulator/videoManipulator"
import {VideoManipulator} from "./videoManipulator/videoManipulator"
import styled from "styled-components"
import {connect, ConnectedProps} from "react-redux"
import {CardStyle} from "../../styles/sharedComponents"
import {saveOriginalData, setPlayerState, skipFrame} from "../../redux/actions/playerActions"
import {RootState} from "../../redux/reducers"
import {PlayerStateUpdate} from "../../redux/actions/playerTypes"
import {
    changeExportState,
    changeProgressNote,
    deleteEdit,
    initializeMotionTracking,
    saveHistory,
    setTemplate,
    toggleAutoTrack
} from "../../redux/actions/editorActions.ts"
import {Edit, ExportState, UploadState} from "../../redux/reducers/editor/types"
import EditButtons from "./EditButtons"
import {goBackInHistory, uploadGif} from "../../redux/actions/commonActions.ts"
import {RouteComponentProps, withRouter} from "react-router"
import {templatesDb, templatesRef} from "../../firebase/firebaseHelper.ts"
import {setUpCanvasTouchAndMouse, uploadFileDialog} from "../../utils/UIHelpers"
import {EditPanel} from "./editPanel/EditPanel"
import {ExportType} from "../../redux/actions/editorActionTypes.ts"
import {LoadingWithNote} from "./LoadingWithNote"

import {ShortTutorial} from "./shortTutorial/ShortTutorial"
import {getRealDelay} from "../../utils/StateHelpers"
import BottomPanel from "./bottomPanel/BottomPanel"

const TransparentContainer = styled.div`
  margin: 2em auto;
  width: fit-content;
`

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: start;
`

const VideoContainer = styled(CardStyle)`
  margin-top: 16px;
  display: flex;
`

const FORWARD_KEY = 68
const BACK_KEY = 65
const REVERT_KET = 90
const MOTION_TRACK_KEY = 87
const PLAY_PAUSE = 32
const BACKSPACE = 8
const TEMPLATE_FAKE_NAME = "template-asfpoubqmncuheamn.gif"

interface IProps extends RouteComponentProps, ConnectedProps<typeof connector> {
    template: string;
}

interface EditorState {
    loading: boolean
}

class Editor extends React.Component<IProps> {
    canvasRef: RefObject<HTMLCanvasElement>
    playbackController!: VideoManipulator
    state: EditorState

    constructor(props: IProps) {
        super(props)
        this.canvasRef = React.createRef()
        this.state = {loading: false}
        console.log("constructor run")
    }

    saveWhenChangingFramesDuringDrag = () => {
        if (this.playbackController.drag !== undefined) {
            this.props.dispatch(saveHistory())
        }
    }

    handleKeyDown = (event: KeyboardEvent) => {
        if (document.activeElement instanceof HTMLInputElement) return
        switch (event.keyCode) {
            case BACK_KEY:
                this.saveWhenChangingFramesDuringDrag()
                this.props.skipFrame(-1)
                break
            case FORWARD_KEY:
                this.saveWhenChangingFramesDuringDrag()
                this.props.skipFrame(1)
                break
            case REVERT_KET:
                if (event.ctrlKey || event.metaKey) {
                    this.props.dispatch(goBackInHistory())
                }
                break
            case MOTION_TRACK_KEY:
                if (!this.props.editor.motionTrackingInProgress) {
                    // @ts-ignore
                    this.props.dispatch(initializeMotionTracking())
                }
                break
            case PLAY_PAUSE:
                event.preventDefault()
                this.props.dispatch(setPlayerState({
                    playing: !this.getCurrentPlayerState().playing,
                    nextFrameScheduled: false
                }))
                break
            case BACKSPACE:
                const id = this.getCurrentEditorState().active
                if (id != null && window.confirm("Delete selected edit?")) {
                    this.props.dispatch(deleteEdit(id))
                }
                break
            default:
                break
        }
    }

    handleLeave = (e: BeforeUnloadEvent) => {
        e.preventDefault();
        e.returnValue = '';
        return "hello"
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown)
        window.removeEventListener("beforeunload", this.handleLeave);
    }

    componentDidMount() {
        window.addEventListener("beforeunload", this.handleLeave);

        const canvas = this.canvasRef.current
        document.addEventListener("keydown", this.handleKeyDown)
        this.playbackController = new VideoManipulator(canvas!, this.getCurrentEditorState, this.getCurrentPlayerState, this.props.dispatch)
        this.playbackController.updatePlayer()

        // TODO fix types
        // @ts-ignore
        const template: string = this.props.match.params?.template?.replace("-", "/")
        if (template) {
            this.props.dispatch(changeProgressNote("Preparing template..."))
            this.setState({
                ...this.state,
                loading: true
            })

            this.props.dispatch(uploadGif(UploadState.UPLOADING))

            // Download template gif
            templatesRef.child(template).getDownloadURL().then(async (url: string) => {
                const success = await this._loadFileIntoEditor(url, TEMPLATE_FAKE_NAME)
                if (success) {
                    // Download and set edit actions
                    const split = template.split("/")
                    const authorId = split[0]
                    const templateId = split[1]

                    const doc = await templatesDb.doc(authorId).collection("userTemplates").doc(templateId).get()
                    const data = doc.data()
                    if (doc.exists && data !== undefined) {
                        this.props.dispatch(setTemplate(data.edits as Edit[]))
                    } else {
                        window.alert("Loading templates failed")
                    }
                }

                this.setState({
                    ...this.state,
                    loading: false
                })
            })
        }

        setUpCanvasTouchAndMouse(canvas!, this.playbackController)
    }

    componentDidUpdate(prevProps: IProps, prevState: {}, snapshot: any) {
        this.playbackController.updatePlayer()

        if (this.props.editor.autoTrackingInProgress &&
            !this.props.editor.motionTrackingInProgress) {
            if (this.props.player.currentFrame !== this.props.editor.endFrame) {
                // @ts-ignore
                this.props.dispatch(initializeMotionTracking())
            } else {
                this.props.dispatch(toggleAutoTrack())
            }
        } else if (!this.props.editor.autoTrackingInProgress) {
            if (this.props.player.playing && !this.props.player.nextFrameScheduled) {
                if (this.props.player.currentFrame === this.props.editor.endFrame && this.props.editor.progress.exportProgress.video === ExportState.RUNNING) {
                    this.playbackController.finishExporting()

                    // TODO remove
                    this.props.dispatch(setPlayerState({
                        playing: false
                    }))
                }

                this.props.dispatch(setPlayerState({
                    currentFrame: this.props.player.currentFrame + 1,
                    nextFrameScheduled: true
                }))


                // TODO calculate diff
                setTimeout(() => {
                    if (this.props.player.nextFrameScheduled) {
                        this.props.dispatch(setPlayerState({
                            nextFrameScheduled: false
                        }))
                    }
                }, getRealDelay(this.props.player.decodedFrames![this.props.player.currentFrame].frameInfo.delay))

            }

            // Handle Export
            if (prevProps.editor.progress.exportProgress.video === ExportState.NONE && this.props.editor.progress.exportProgress.video === ExportState.STARTED && this.playbackController) {
                this.props.dispatch(changeExportState(ExportState.RUNNING, ExportType.WEBM))
                this.playbackController._exportVideo()
            }
        }
    }

    _loadFileIntoEditor = async (url: string, name?: string): Promise<boolean> => {
        let resourceUrl = url.startsWith("blob") || name === TEMPLATE_FAKE_NAME ? url : "https://cors-anywhere.herokuapp.com/" + url
        try {
            // const isGif = url?.endsWith('.gif') || name?.endsWith('.gif')
            // if (!isGif) {
            //     const ffmpeg = createFFmpeg({ log: true });
            //     const inputName = 'input'
            //     this.props.dispatch(changeProgressNote("Initializing FFMPEG..."))
            //     await ffmpeg.load();
            //     this.props.dispatch(changeProgressNote("Downloading file..."))
            //     const file = await fetchFile(resourceUrl)
            //     this.props.dispatch(changeProgressNote("Converting into a GIF..."))
            //     ffmpeg.FS('writeFile', inputName, file);
            //     await ffmpeg.run('-i', inputName,  'output.gif');
            //     const data = ffmpeg.FS('readFile', 'output.gif');
            //
            //     resourceUrl = URL.createObjectURL(new Blob([data.buffer], { type: 'image/gif' }));
            // }
            this.props.dispatch(changeProgressNote("Loading GIF..."))
            const gifData = await gm.loadGif(resourceUrl)
            if (gifData === undefined) {
                throw Error("Gif couldn't be loaded")
            }

            this.props.dispatch(changeProgressNote("Decoding GIF..."))
            const result = await gm.decodeGif(gifData)
            this.props.dispatch(changeProgressNote("Preparing editor..."))
            const frames = result.decodedFrames
            const gifInfo = result.gifInfo
            // Add renderable buffer, it cannot be done on background
            for (let i = 0; i < frames.length; i++) {
                frames[i].buffer = gm.createBuffer(frames[i], frames[0].frameInfo.width, frames[0].frameInfo.height)
            }

            this.props.dispatch(uploadGif(
                UploadState.UPLOADED,
                {...gifInfo, frameCnt: frames.length},
                frames
            ))
            this.props.dispatch(saveOriginalData(gifData))

            return true
        } catch (e) {
            console.log(e.message)
            this.props.dispatch(uploadGif(UploadState.FAILED))
            return false
        }
    }

    getCurrentEditorState = () => {
        return this.props.editor
    }

    getCurrentPlayerState = () => {
        return this.props.player
    }

    uploadUrl = (url: string) => {
        this.props.dispatch(uploadGif(UploadState.UPLOADING))
        this._loadFileIntoEditor(url)
    }

    uploadFile = () => {
        uploadFileDialog((url, name) => {
            this.props.dispatch(uploadGif(UploadState.UPLOADING))
            this._loadFileIntoEditor(url, name)
        })
    }

    render() {
        return (
            <React.Fragment>
                <TransparentContainer>
                    {/*<EditorTitle>Gif editor</EditorTitle>*/}
                    <EditButtons uploadUrl={this.uploadUrl} uploadFile={this.uploadFile}/>
                    <CardsContainer>
                        <VideoContainer id="asdf">
                            <canvas id="canvas" ref={this.canvasRef} height={this.props.player.videoInfo.height}
                                    width={this.props.player.videoInfo.width}
                                    style={this.props.player.decodedFrames && !this.state.loading ? {} : {display: "none"}}
                            />
                            {this.state.loading && <div style={{padding: "50px"}}><LoadingWithNote/></div>}
                            {!this.state.loading && !this.props.player.decodedFrames &&
                            <ShortTutorial/>
                            }
                        </VideoContainer>
                        {!this.state.loading && this.props.player.decodedFrames &&
                        <EditPanel/>
                        }
                    </CardsContainer>
                </TransparentContainer>
                {this.props.player.decodedFrames && <BottomPanel/>}
            </React.Fragment>)
    }
}

const connector = connect(
    (state: RootState) => ({
        player: state.player,
        editor: state.editor
    }),
    (dispatch) => ({
        setPlayerState: (playerState: PlayerStateUpdate) => {
            dispatch(setPlayerState(playerState))
        },
        skipFrame: (skipCnt: number) => {
            dispatch(skipFrame(skipCnt))
        },
        dispatch
    })
)

export default connector(withRouter(Editor))