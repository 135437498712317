import styled from "styled-components"
import React from "react"
import firebase from "firebase/app"
import {Button, CardStyle, ITheme} from "../../styles/sharedComponents"
import {auth} from "../../firebase/firebaseHelper"


const Container = styled.div`
  display: flex;
  align-items: center;
  padding-right: 20px;
  z-index: 100;
`

const Menu = styled(CardStyle)`
  clip-path: inset(0px -50px -50px -50px);
  border-radius: 0 0 5px 5px;
  position: absolute;
  top: 50px;
  height: 60px;
  background: ${(props: { theme: ITheme }) => props.theme.light};
  width: 200px;
  overflow:hidden;
  transition:transform 0.1s ease-out; // note that we're transitioning transform, not height!
  transform:scaleY(0); // implicit, but good to specify explicitly
  transform-origin:top;
  
  ${Container}:hover & {
    transform: scaleY(1);
  }
`

const LogoutBtn = styled(Button)`
  margin: 10px;
  background: white;
    color: #0a0a0a;
    font-size: 0.8em;
    width: 80%;
    padding: 8px 16px;
    box-shadow: 1px 1px 2px 0 #929292;
    &:hover {
      background: #f8f8f8;
    }
    
`

interface PhotoContainerProps {
    url: string
}

const PhotoContainer = styled.div<PhotoContainerProps>`
    background: url(${props => props.url})  no-repeat center center;
    background-size: cover;
    height: 36px;
    width: 36px;
    border-radius: 18px;
    border: 1px solid black;
    margin-right: 8px;
    margin-left: 20px;
`

function _logout() {
    auth.signOut()
}

export function LoggedIn(user: firebase.User) {
    return <Container>
        <Menu>
            <LogoutBtn onClick={_logout}>Log out</LogoutBtn>
        </Menu>
        <PhotoContainer url={user.photoURL!}/>
        {user.displayName}

    </Container>
}