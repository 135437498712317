import styled from "styled-components"
import {Line} from "../EditPanel"
import * as React from "react"
import {useEffect, useMemo, useRef} from "react"
import {useDispatch, useSelector} from "react-redux"
import {getActiveEdit, getCurrentMoveIdx, readableMove} from "../../../../utils/StateHelpers"
import clearImg from "../../../../img/clear-24px.svg"
import clearWhiteImg from "../../../../img/clear-white-24px.svg"
import {Button, SecondaryButton} from "../../../../styles/sharedComponents"
import {Edit, Move} from "../../../../redux/reducers/editor/types"
import {
    center,
    removeAllMovePoints,
    removeMovePoint
} from "../../../../redux/actions/editorActions"
import {RootState} from "../../../../redux/reducers"
import {setFrame} from "../../../../redux/actions/playerActions"
import centerImg from "../../../../img/center.svg"
import {MotionTrackingControls} from "./MotionTrackingControls"

const ClearPositionsButton = styled(Button)`
  background: url(${clearWhiteImg}) #f07fa2 no-repeat;
  background-size: 12px;
  height: 16px;
  line-height: 16px;
  padding: 0 6px 0 16px;
  background-position-y: center;
  background-position-x: 3px;
  margin-top: 4px;
  
  &:hover {
    background-color: #ff89ac;
  }
`

const CenterButton = styled(SecondaryButton)`
  margin-left: auto;
  background-image: url(${centerImg});
  background-repeat: no-repeat;
  background-size: 10px;
  font-size: 0.5em;
  padding: 4px 8px 4px 22px;
  background-position-y: center;
  background-position-x: 6px;
`

const RemovePositionBtn = styled.button`
  background: url(${clearImg});
  background-size: 16px;
  width: 16px;
  height: 16px;
  border: none;
  cursor: pointer;
  margin-left: 4px;
  :focus {
    outline: none;
  }
`

const ScrollWrapper = styled.div`
overflow: auto;
  height: 185px;
  margin-top: 8px;
  border: 1px solid #e0e0e0;
`

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 0.8em;
  margin-bottom: 8px;
    
  & th {
    line-height: 2em;
    text-align: left;
    text-transform: uppercase;
    background: #2b2b2b;
    color: white;
    font-size: 0.8em;
  }
  
  & td {
    font-weight: normal;
    color: #505050;
    line-height: 16px;
    padding: 4px;
  }
`

const Container = styled.div`
  padding-left: 2px;
  // fixes shadows
  padding-right: 2px;
  flex: 1;
`

interface TableLineProps {
    active: boolean,
}

const TableLine = styled.tr<TableLineProps>`
  // fixed is safari workaround
  background: ${props => props.active ? "rgb(233, 233, 233)" : "white"};
  height: 30px;
  
  :hover {
    background: ${props => props.active ? "rgb(233, 233, 233)" : "#f9f9f9"};
    cursor: pointer;
  }
`

function TableLineFunc(props: { activeEdit: Edit, idx: number }) {
    const dispatch = useDispatch()

    // Redraw only when switches
    const isActive = useSelector((rootState: RootState) => {
        return props.idx === getCurrentMoveIdx(props.activeEdit.moves as Move[], rootState.player.currentFrame)
    })

    const move = readableMove(
        useSelector((rootState: RootState) => {
            return (getActiveEdit(rootState.editor)!.moves as Move[])[props.idx]
        })
    )

    return (
        <TableLine
            key={move.frame}
            active={isActive}
            onClick={() => dispatch(setFrame(move.frame))}
        >
            {(props.activeEdit.moves as Move[]).length !== 1 ?
                <td style={{width: "28px", verticalAlign: "text-bottom"}}>
                    <RemovePositionBtn onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        event.stopPropagation()
                        dispatch(removeMovePoint(move.frame))
                    }
                    }/>
                </td>
                : <td/>
            }
            <td>{move.frame + 1}</td>
            <td>{move.x}, {move.y}</td>
            <td>{move.width}, {move.height}</td>
            <td>{move.rotation}</td>
        </TableLine>
    )
}

export function AnimationControls(props: {}) {
    const activeEdit = useSelector((state: RootState) => getActiveEdit(state.editor))!
    const currentFrame = useSelector((state: RootState) => state.player.currentFrame)
    const activeMoveIdx = (Array.isArray(activeEdit.moves)) ? getCurrentMoveIdx(activeEdit.moves, currentFrame) : 0
    const isAnimated = Array.isArray(activeEdit.moves)
    const moves = activeEdit.moves

    const tableRef = useRef<HTMLTableElement>(null)

    const dispatch = useDispatch()


    useEffect(() => {
        tableRef?.current?.scrollTo(0, activeMoveIdx * 30 - 60)
    }, [activeMoveIdx, moves])

    const redrawTrigger = Array.isArray(moves) ? moves.length : moves

    const tableLines = useMemo(() => {
        if (Array.isArray(moves)) {
            const positions = moves as Move[] // TS is inferior to kotlin ...
            return positions.map((realMove: Move, idx: number) => {
                return (
                    <TableLineFunc activeEdit={activeEdit} idx={idx} key={idx}/>
                )
            })
        } else {
            return (<TableLineFunc activeEdit={activeEdit} idx={0}/>)
        }
    }, [redrawTrigger])


    function deleteAllMoves() {
        if (window.confirm("Delete all positions?")) {
            dispatch(removeAllMovePoints())
        }
    }

    return (
        <Container>
            {isAnimated && <React.Fragment>
                <MotionTrackingControls/>
                <ScrollWrapper ref={tableRef}>
                    <Table>
                        <thead>
                        <tr>
                            <th/>
                            <th>frame</th>
                            <th>position</th>
                            <th>size</th>
                            <th>rotation</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tableLines}
                        </tbody>
                    </Table>
                </ScrollWrapper>
                <Line>
                    <ClearPositionsButton onClick={deleteAllMoves}>
                        clear positions
                    </ClearPositionsButton>
                    <CenterButton onClick={() => dispatch(center())}>Center</CenterButton>
                </Line>
            </React.Fragment>}
        </Container>
    )

}