import * as React from "react"
import unfoldImg from "../../../../img/unfold_more-24px.svg"
import styled from "styled-components"

const Container = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`

interface IProps {
    expanded: boolean
    expandHandler: () => void
}

function ExpandButton(props: IProps) {
    return (
        <Container>
            <input type="checkbox" checked={props.expanded}  onChange={props.expandHandler}/>
            <img src={unfoldImg} alt="unfold"/>
        </Container>
    )
}

export default ExpandButton
