import styled from "styled-components";
import { CardStyle, Loader } from "../../styles/sharedComponents.ts";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
//import { exportedRef } from "../../firebase/firebaseHelper.tsx";

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DetailContainer = styled(CardStyle)`
  margin: 32px auto;
  display: inline-block;
  padding: 32px;
`;

function video(url?: string) {
  if (!url) {
    return <Loader />;
  } else {
    return <img src={url} crossOrigin={"anonymous"} alt={""} />;
  }
}

export function Detail() {
  const [url, setUrl] = useState<string | undefined>(undefined);
  const { userId, id } = useParams();

  useEffect(() => {
    exportedRef
      .child(userId)
      .child(id)
      .getDownloadURL()
      .then((url: string) => {
        setUrl(url);
      })
      .catch(() => {
        setUrl("");
      });
  }, [id, userId]);

  return (
    <Center>
      <DetailContainer>{video(url)}</DetailContainer>
    </Center>
  );
}
