

// Edited version of https://github.com/SMUsamaShah/CanvasRecorder

export class CanvasRecorder {

    mediaRecorder!: MediaRecorder
    stream!: MediaStream
    onStopped!: (blob: Blob) => void

    constructor(canvas: HTMLCanvasElement)  {
        try {
            // @ts-ignore
            this.stream = canvas.captureStream()
            if (typeof this.stream == undefined || !this.stream) {
                console.log("not supported")
            }
        } catch (e) {
            console.log("canvas recorder not supported")
        }
    }

    startRecording(onStarted: () => void, onStopped: (blob?: Blob) => void) {
        try {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const test = MediaRecorder.name
        } catch (e) {
            onStopped(undefined)
            return
        }

        if (!this.stream) {
            onStarted()
            return
        }


        this.onStopped = onStopped
        const recordedBlobs: Blob[] = []

        const types = [
            'video/mp4',
            'video/webm'
        ];

        let type: string | undefined
        for (let i in types) {
            if (MediaRecorder.isTypeSupported(types[i])) {
                type = types[i];
                break;
            }
        }

        if (!type) {
            onStopped(undefined)
            return
        }

        const options = {
            mimeType: type,
            videoBitsPerSecond: 1750000
        }

        try {
            this.mediaRecorder = new MediaRecorder(this.stream, options)
        } catch (e) {
            alert('MediaRecorder is not supported by this browser.')
            console.error('Exception while creating MediaRecorder:', e)
            return;
        }

        console.log('Created MediaRecorder', this.mediaRecorder, 'with options', options);
        this.mediaRecorder.onstop = () => {
            const blob = new Blob(recordedBlobs, {type: type})
            this.onStopped(blob)
        }

        this.mediaRecorder.ondataavailable =  (event:BlobEvent) => {
            if (event.data && event.data.size > 0) {
                recordedBlobs.push(event.data);
            }
        }

        // Firefox start delay caused here
        this.mediaRecorder.onstart = onStarted
        this.mediaRecorder.start();
    }

    stopRecording() {
        this.mediaRecorder.stop();
    }

}