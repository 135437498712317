import * as React from "react"
import styled from "styled-components"
import {BaseHint, SecondaryButton} from "../../../../styles/sharedComponents"
import motionTrackImg from "../../../../img/motion_track.svg"
import pauseImg from "../../../../img/pause-white-24px.svg"
import playImg from "../../../../img/play_arrow-white-24px.svg"
import {useDispatch, useSelector} from "react-redux"
import {initializeMotionTracking, toggleAutoTrack, toggleMotionTracking} from "../../../../redux/actions/editorActions"
import {RootState} from "../../../../redux/reducers"
import {getActiveEdit, hasAnchor} from "../../../../utils/StateHelpers"
import tutorial from "./tutorial/rec.mp4"
import {useStateWithLocalStorage} from "../../../../utils/CustomHooks"

const MotionTrackBtn = styled(SecondaryButton)`
  background-image: url(${motionTrackImg});
  flex-grow: 1;
  justify-content: center;
  background-size: 12px;
  min-height: 18px;
  height: 100%;
  padding: 0 8px 0 22px;
  background-position-y: center;
  background-position-x: 6px;
  background-repeat: no-repeat;
`

interface IAutoTrackBtn {
    playing: boolean
}

const AutoTrackingBtn = styled(SecondaryButton)<IAutoTrackBtn>`
  background-image: url(${props => props.playing ? pauseImg : playImg});
  background-size: 12px;
  line-height: 12px;
  margin-left: 8px;
  background-position-y: center;
  background-repeat: no-repeat;
  padding: 0 4px 0 18px;
  background-position-x: 2px;
  min-height: 14px;
  position: relative;
`

const MotionTrackLine = styled.div`
  display: flex;
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 6px;

  & label {
    cursor: pointer;
    font-weight: bold;
    color: #545454;
  }

  & input {
    position: relative;
    top: -2px;
    margin-left: 6px;
    cursor: pointer;
  }
`

const Hint = styled(BaseHint)`
  width: 259px;
  top: 31px;
  transition-delay: 0.3s;
`

const AutotrackHint = styled(Hint)`
  ${AutoTrackingBtn}:hover ~ &, &:hover {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
  }
`

const MotionTrackHint = styled(Hint)`
  & video {
    width: 100%;
  }

  ${MotionTrackBtn}:hover ~ &, &:hover {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
  }
`

const HideHint = styled.button`
  color: grey;
  user-select: none;
  border: none;
  background: inherit;
  cursor: pointer;
  padding: 0;
`

const ButtonsLine = styled.div`
  height: 28px;
  display: flex;
  position: relative;
  align-items: center;
`

export function MotionTrackingControls(props: {}) {
    const motionTrack = useSelector((state: RootState) => hasAnchor(getActiveEdit(state.editor)!.moves))
    const possibleToMotionTrack = useSelector(
        (state: RootState) =>
            state.player.currentFrame !== state.editor.endFrame &&
            state.player.currentFrame !== state.player.videoInfo.frameCnt - 1 &&
            !state.editor.motionTrackingInProgress
    )
    const autoTrackInProgress = useSelector((state: RootState) => state.editor.autoTrackingInProgress)
    const [hideMotionTrackHint, setHideMotionTrackHint] = useStateWithLocalStorage("hideMotionTrackHint")
    const [hideAutoTrackHint, setHideAutoTrackHint] = useStateWithLocalStorage("hideAutoTrackHint")

    const dispatch = useDispatch()

    return <React.Fragment>
        <MotionTrackLine onClick={() => dispatch(toggleMotionTracking())}>
            <label>
                Show motion tracking controls:
            </label>
            <input type="checkbox" checked={motionTrack} readOnly/>
        </MotionTrackLine>
        {motionTrack &&
        <React.Fragment>
            <ButtonsLine>
                <MotionTrackBtn onClick={() => dispatch(initializeMotionTracking())}
                                disabled={!possibleToMotionTrack}>
                    Motion track
                </MotionTrackBtn>

                {hideMotionTrackHint !== "hide" &&
                <MotionTrackHint>
                    <HideHint onClick={() => setHideMotionTrackHint("hide")}>[x] Hide hint</HideHint>
                    <video src={tutorial} loop autoPlay/>
                </MotionTrackHint>
                }

                <AutoTrackingBtn
                    onClick={() => dispatch(toggleAutoTrack())}
                    playing={autoTrackInProgress}
                    disabled={!possibleToMotionTrack && !autoTrackInProgress}
                >
                    (Advanced) Auto Track
                </AutoTrackingBtn>
                {hideAutoTrackHint !== "hide" &&
                <AutotrackHint>
                    <HideHint onClick={() => setHideAutoTrackHint("hide")}>[x] Hide hint</HideHint>
                    <p>Continues motion tracking until the algorithm reaches high uncertainty. <b>Does not work well for
                        chaotic or low quality gifs.</b></p>
                </AutotrackHint>
                }
            </ButtonsLine>
        </React.Fragment>
        }
        <hr/>
    </React.Fragment>
}