import * as React from "react"
import {FunctionComponent, useState} from "react"
import styled from "styled-components"
import addImg from "../../img/add-24px.svg"
import publishImg from "../../img/publish-24px.svg"
import uploadImg from "../../img/cloud_upload-24px.svg"
import revertImg from "../../img/restore-24px.svg"
import {useDispatch, useSelector} from "react-redux"
import {Button} from "../../styles/sharedComponents"
import {addText, addTitleSpace, changeExportState, toggleWatermark} from "../../redux/actions/editorActions"
import {ExportDialog} from "./dialogs/exportDialog/ExportDialog"
import {Dispatch} from "redux"
import {EditorActionTypes, ExportType} from "../../redux/actions/editorActionTypes"
import {RootState} from "../../redux/reducers"
import {ExportState, UploadState} from "../../redux/reducers/editor/types"
import {UploadDialog} from "./dialogs/UploadDialog"
import {goBackInHistory, uploadGif} from "../../redux/actions/commonActions"
import {CommonActionTypes} from "../../redux/actions/commonTypes"
import addPicImg from "../../img/add_photo_alternate-24px.svg"
import {PictureUploadDialog} from "./dialogs/PictureUploadDialog"
import {Shortcuts} from "./Shortcuts"

const Container = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  align-items: center;
  justify-content: space-evenly;
`
const LeftContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 4px;
`

const RightContainer = styled.div`
  padding: 0 32px;
  display: flex;
  flex-wrap: wrap;
`

const UploadButton = styled(Button)`
  font-size: 18px;
  padding: 10px;
  margin-top: 4px;
`

const ExportButton = styled(Button)`
  font-size: 18px;
  margin-right: 16px;
  margin-top: 4px;
  padding: 10px;
`

const EditButton = styled(Button)`
  margin-right: 8px;
  margin-top: 4px;
`

const EditButtons: FunctionComponent<{ uploadFile: () => void, uploadUrl: (url: string) => void }> = ({
                                                                                                          uploadFile,
                                                                                                          uploadUrl
                                                                                                      }) => {
    const exportInProgress = useSelector((state: RootState) =>
        state.editor.progress.exportProgress.gif === ExportState.RUNNING ||
        state.editor.exported.exportedGif !== undefined ||
        state.editor.progress.exportProgress.video === ExportState.RUNNING ||
        state.editor.exported.exportedVideo !== undefined)

    const uploadInProgress = useSelector((state: RootState) => state.editor.progress.uploadProgress === UploadState.UPLOADING)
    const gifUploaded = useSelector((state: RootState) => state.player.decodedFrames != null)
    const watermark = useSelector((state: RootState) => state.editor.watermark)
    const titleSpace = useSelector((state: RootState) => state.editor.titleSpace)
    const hasHistory = useSelector((state: RootState) => state.history.history.length > 0)
    const hasTitleSpace = titleSpace > 0

    const dispatch = useDispatch<Dispatch<CommonActionTypes | EditorActionTypes>>()
    const [showingUploadDialog, setShowUploadDialog] = useState(false)
    const [showingPictureUploadDialog, setShowPictureUploadDialog] = useState(false)

    const showUploadDialog = (confirm: boolean) => {
        if (!confirm || window.confirm("Current project will be deleted. Do you want to proceed?")) {
            setShowUploadDialog(true)
            dispatch(uploadGif(UploadState.NONE))
        }
    }

    const showImgUploadDialog = () => {
        setShowPictureUploadDialog(true)
    }

    const exportDialog = <ExportDialog/>
    const uploadDialog = <UploadDialog
        uploadFile={uploadFile} uploadUrl={uploadUrl}
        showUploadDialog={setShowUploadDialog}/>

    const pictureUploadDialog = <PictureUploadDialog showPictureUploadDialog={setShowPictureUploadDialog}/>

    return (
        <React.Fragment>
            <Container>
                {gifUploaded &&
                <LeftContainer>
                    <Shortcuts/>
                    <EditButton onClick={() => dispatch(goBackInHistory())} disabled={!hasHistory}>
                        <img src={revertImg} alt="revert"/>
                        Undo
                    </EditButton>
                    <EditButton onClick={() => dispatch(addText())}>
                        <img src={addImg} alt="add"/>
                        Add&nbsp;Text
                    </EditButton>
                    <EditButton onClick={showImgUploadDialog}>
                        <img src={addPicImg} alt='addImage'/>
                        Add&nbsp;Image
                    </EditButton>
                    <EditButton onClick={() => dispatch(addTitleSpace(hasTitleSpace ? 0 : 100))}
                                style={{marginLeft: "6px"}}>
                        {hasTitleSpace ? "Remove title space" : "Add title space"}
                        <input type="checkbox" checked={hasTitleSpace} readOnly/>
                    </EditButton>

                    <EditButton onClick={() => dispatch(toggleWatermark())}>
                        Watermark
                        <input
                            type="checkbox"
                            checked={watermark}
                            readOnly
                        />
                    </EditButton>

                </LeftContainer>
                }

                <RightContainer>
                    {gifUploaded && <ExportButton
                        onClick={() => {
                            dispatch(changeExportState(ExportState.STARTED, ExportType.GIF))
                            dispatch(changeExportState(ExportState.STARTED, ExportType.WEBM))
                        }}
                        disabled={uploadInProgress}
                    >
                        <img src={publishImg} alt="add"/>
                        Export
                    </ExportButton>}
                    <UploadButton
                        onClick={() => {
                            showUploadDialog(gifUploaded)
                        }}
                        disabled={uploadInProgress}>
                        <img src={uploadImg} alt="add"/>
                        New&nbsp;project
                    </UploadButton>
                </RightContainer>
            </Container>
            {showingUploadDialog && uploadDialog}
            {exportInProgress && exportDialog}
            {showingPictureUploadDialog && pictureUploadDialog}
        </React.Fragment>
    )
}

export default React.memo(EditButtons)