import React from "react"
import {AnimationControls} from "./animation/AnimationControls"
import styled from "styled-components"
import {useSelector} from "react-redux"
import {RootState} from "../../../redux/reducers"
import {getActiveEdit} from "../../../utils/StateHelpers"
import {ImageEdit} from "../../../redux/reducers/editor/types"

const Container = styled.div`
  min-width: 300px;
`
const AnimateToggle = styled.div`
  display: flex;
  cursor: pointer;

  & label {
    color: #545454;
    size: 1.3em;
    font-weight: bold;
    cursor: pointer;
  }

  & input {
    margin-left: 6px;
    position: relative;
    top: -2px;
    cursor: pointer;
  }
`

const Image = styled.img`
  height: 30px;
`

export function ImageEditEditor(props: {toggleAnimate: () => void}) {
    const edit = useSelector((state: RootState) => getActiveEdit(state.editor)) as ImageEdit
    const isAnimated = Array.isArray(edit.moves)

    return <Container>
        <Image src={edit.img}/>
        <AnimateToggle onClick={props.toggleAnimate} style={{fontSize: '1em'}}>
            <label htmlFor="male">Animated: </label>
            <input type="checkbox" name="animate" id="animate" checked={isAnimated} readOnly/>
        </AnimateToggle>
        <AnimationControls/>
    </Container>

}