import {anchorIsSelected, fontToWeb, getActiveEdit, getCurrentSizeAndPosition} from "../../../utils/StateHelpers"
import {Edit, EditorState, Move, MoveWithAnchor, Position, TextEdit} from "../../../redux/reducers/editor/types"
import {Drag, Dragger, DragType} from "./types"
import {rotate, Vector} from "../../../utils/MathHelpers"


export interface Border {
    padding: number,
    x: number,
    y: number,
    startX: number,
    startY: number,
    width: number,
    height: number,
    rotation: number,
    draggers: Dragger[]
}

export const getBorder = (moves: Position | Move[], frameIdx: number, isAnchor: boolean = false): Border => {
    const padding = 1
    const cornerSize = 10
    const draggableState = getCurrentSizeAndPosition(moves, frameIdx)


    const corners = true
    const hSides = true
    const vSides = true
    const rotation = !isAnchor

    const cornerPos = []
    if (corners) {
        cornerPos.push(
            [0, 0, DragType.RESIZE_00],
            [0, 1, DragType.RESIZE_01],
            [1, 0, DragType.RESIZE_10],
            [1, 1, DragType.RESIZE_11]
        )
    }

    if (hSides) {
        cornerPos.push(
            [0, 0.5, DragType.RESIZE_L],
            [1, 0.5, DragType.RESIZE_R]
        )
    }

    if (vSides) {
        cornerPos.push(
            [0.5, 0, DragType.RESIZE_T],
            [0.5, 1, DragType.RESIZE_B]
        )
    }

    if (rotation) {
        cornerPos.push(
            [0.5, -0.3, DragType.ROTATE]
        )
    }

    const draggers: Dragger[] = []
    const adjBoxWidth = draggableState.width + 2 * padding
    const adjBoxHeight = draggableState.height + 2 * padding

    const startX = draggableState.x - adjBoxWidth / 2
    const startY = draggableState.y - adjBoxHeight / 2

    cornerPos.forEach(
        (corner) => {
            const x = corner[0] * adjBoxWidth + startX
            const y = corner[1] * adjBoxHeight + startY

            draggers.push({
                x, y,
                startX: corner[0] * adjBoxWidth + startX - cornerSize / 2,
                startY: corner[1] * adjBoxHeight + startY - cornerSize / 2,
                width: cornerSize,
                height: cornerSize,
                type: corner[2],
                outer: corner[2] === DragType.ROTATE
            })
        }
    )

    return {
        padding,
        x: draggableState.x,
        y: draggableState.y,
        startX,
        startY,
        rotation: draggableState.rotation,
        width: adjBoxWidth,
        height: adjBoxHeight,
        draggers
    }
}

export const detectCornerType = (mouseX: number, mouseY: number, moves: Position | Move[], frameIdx: number, isAnchor: boolean) => {
    if (moves === null) {
        return undefined
    }

    const border = getBorder(moves, frameIdx, isAnchor)
    const centerVec: Vector = {
        x: border.x,
        y: border.y
    }

    const {rotation} = getCurrentSizeAndPosition(moves, frameIdx)
    // A little lesson in trickery: instead of rotating points, I rotate mouse, iq 9000
    const {x, y} = rotate({x: mouseX, y: mouseY}, -rotation, centerVec)

    for (let i = 0; i < border.draggers.length; i++) {
        const corner = border.draggers[i]
        if (
            x > corner.startX &&
            x < corner.startX + corner.width &&
            y > corner.startY &&
            y < corner.startY + corner.height
        ) {
            return corner.type
        }
    }

    return undefined
}

export const isIn = (moves: Position | Move[], mouseX: number, mouseY: number, frame: number) => {
    const draggableState = getCurrentSizeAndPosition(moves, frame)
    const centerVec: Vector = {
        x: draggableState.x,
        y: draggableState.y
    }
    const {x, y} = rotate({x: mouseX, y: mouseY}, -draggableState.rotation, centerVec)

    return x >= draggableState.startX &&
        x <= draggableState.startX + draggableState.width &&
        y >= draggableState.startY &&
        y <= draggableState.startY + draggableState.height
}

export const detectEdit = (x: number, y: number, edits: Edit[], frameIdx: number): Edit | null => {
    for (const edit of edits.values()) {
        if (
            isEditVisible(edit, frameIdx) &&
            isIn(edit.moves, x, y, frameIdx)
        ) {
            return edit
        }
    }

    return null
}

export const detectAnchor = (x: number, y: number, edit: Edit, frameIdx: number): boolean => {
    if (!Array.isArray(edit.moves) || !edit.moves[0].hasOwnProperty("anchorPosition")) {
        return false
    }
    const anchorPositions: Move[] = (edit.moves as MoveWithAnchor[]).map(value => {
        return {...value.anchorPosition, frame: value.frame}
    })

    return isEditVisible(edit, frameIdx) && isIn(anchorPositions, x, y, frameIdx)
}

export const getCursor = (x: number, y: number, state: EditorState, frameIdx: number, drag?: Drag): string => {
    if (drag !== undefined) {
        if (drag.type === DragType.MOVE) {
            return "grabbing"
        } else {
            return "pointer"
        }
    } else {
        // YOLO, just pushing browser performance until it lags
        const currentEdit = getActiveEdit(state)
        if (currentEdit !== null) {
            const cornerType = detectCornerType(
                x, y,
                anchorIsSelected(currentEdit.moves, state.anchorSelected) ?
                    (currentEdit.moves as MoveWithAnchor[]).map((value) => {
                        return {...value.anchorPosition, frame: value.frame}
                    }) :
                    currentEdit.moves,
                frameIdx,
                state.anchorSelected
            )

            switch (cornerType) {
                case DragType.RESIZE_00:
                    return "nw-resize"
                case DragType.RESIZE_01:
                    return "sw-resize"
                case DragType.RESIZE_10:
                    return "ne-resize"
                case DragType.RESIZE_11:
                    return "se-resize"
                case DragType.RESIZE_L:
                    return "w-resize"
                case DragType.RESIZE_R:
                    return "e-resize"
                case DragType.RESIZE_T:
                    return "n-resize"
                case DragType.RESIZE_B:
                    return "s-resize"
                case DragType.ROTATE:
                    return "ew-resize"
                default:
                    const edit = detectEdit(x, y, state.edits, frameIdx)
                    if ((edit === currentEdit && !state.anchorSelected)) {
                        return "grab"
                    } else if (edit !== null || detectAnchor(x, y, currentEdit, frameIdx)) {
                        return "pointer"
                    } else {
                        return "default"
                    }
            }
        } else {
            const edit = detectEdit(x, y, state.edits, frameIdx)
            if (edit != null) {
                return "pointer"
            } else {
                return "default"
            }
        }
    }
}

interface textParams {
    textSize: number,
    lines: string[]
}

const getMultilineTextCache = new Map<string, textParams>()
export const getMultilineText = (ctx: CanvasRenderingContext2D, edit: TextEdit, frame: number): textParams => {
    const draggableState = getCurrentSizeAndPosition(edit.moves, frame)
    const hash = draggableState.width.toString() + draggableState.height.toString() + edit.font + edit.text

    if (getMultilineTextCache.has(hash)) {
        return getMultilineTextCache.get(hash)!
    }

    let textSize = 512
    let searchStep = -textSize / 2
    let lines = []
    let words = edit.text.trim().split(" ")
    // This is a weird bug that I couldnt reproduce
    if (!words) {
        words = [""]
    }

    while (true) {
        textSize += searchStep
        lines = []
        ctx.font = `${textSize}px ${fontToWeb(edit.font)}`
        const maxWidth = words.reduce((acc, word) => {
            const width = ctx.measureText(word).width
            if (width > acc) {
                return width
            } else {
                return acc
            }
        }, 0)

        console.log(maxWidth, draggableState.width)

        for (let j = 0; j < words.length; j++) {
            const lineIdx = lines.length - 1
            const word = words[j]
            const potentialLine = lines.length === 0 ? word : lines[lineIdx] + " " + word
            if (ctx.measureText(potentialLine).width > draggableState.width || lines[lineIdx] === undefined) {
                lines.push(word)
            } else {
                lines[lineIdx] += " " + word
            }
        }

        if (Math.abs(searchStep) < 0.5 && textSize * lines.length < draggableState.height) {
            break
        } else if (textSize * lines.length > draggableState.height || maxWidth > draggableState.width) {
            searchStep = -Math.abs(searchStep / 2)
        } else if (textSize * lines.length < draggableState.height && maxWidth < draggableState.width) {
            searchStep = Math.abs(searchStep / 2)
        } else {
            break
        }
    }


    const res: textParams = {
        textSize,
        lines
    }

    getMultilineTextCache.set(hash, res)
    return res
}

export const isEditVisible = (edit: Edit, frameIdx: number): boolean => {
    return edit.startFrame <= frameIdx && edit.endFrame >= frameIdx
}


//export const drawRotated(source: Canva)