import PriorityQueue from "priorityqueuejs"
import {MotionTrackingResult} from "./motionTracker"
import * as Sentry from "@sentry/browser"

export function drawAnchorImageWithScore(anchorStartX: number, anchorStartY: number, w: number, h: number,
                                         image: ImageData, oldAnchor: ImageData, prevX: number, prevY: number) {
    const testingCanvas = document.getElementById("asdfCanvas") as HTMLCanvasElement
    if (testingCanvas == null) {
        return
    }
    const testCtx = testingCanvas.getContext("2d")!!
    const anchorImg = cutNewAnchor(image, anchorStartX, anchorStartY, w, h)

    testCtx.fillStyle = "rgb(0,0,0)"
    testCtx.fillRect(oldAnchor.width, 0, oldAnchor.width, oldAnchor.height * 4)


    let pixels = anchorImg.data.slice()
    for (let j = 0; j < 3; j++) {
        for (let i = 0; i < pixels.length; i += 4) {
            let lightness = getGrey(anchorImg, i, j)
            pixels[i] = lightness
            pixels[i + 1] = lightness
            pixels[i + 2] = lightness
        }
        testCtx.putImageData(new ImageData(pixels, w), w, j * h)
    }


    testCtx.fillStyle = "rgb(255,255,255)"
    testCtx.fillText(getDiff(image, oldAnchor, anchorStartX, anchorStartY, prevX, prevY).toString(), oldAnchor.width, h * 3 + 10)
}

export function combinedSearch2(image: ImageData, oldAnchor: ImageData, startX: number, startY: number, checkSize: number, checkStep: number, testCtx?: CanvasRenderingContext2D): MotionTrackingResult {
    const initialPoints: FilledNode[] = []
    if (checkSize % 2 !== 0) {
        checkSize += 1
    }

    for (let i = -checkSize / 2; i < checkSize / 2; i += checkStep) {
        for (let j = -checkSize / 2; j < checkSize / 2; j += checkStep) {
            const x = startX + i
            const y = startY + j

            if (x < 0 || x > image.width || y < 0 || y > image.height) {
                continue
            }
            const diff = getDiff(image, oldAnchor, x, y, startX, startY)
            initialPoints.push({x, y, diff})

            if (testCtx != null) {
                testCtx.fillStyle = "rgba(255,255,255," + diff / 10 + ")"
                testCtx.fillRect(x, y, 1, 1)
            }
        }
    }

    return greedySearch(image, oldAnchor, startX, startY, initialPoints, 500, testCtx)
}

interface Node {
    x: number,
    y: number,
}

interface FilledNode extends Node {
    diff: number
}

export function greedySearch(image: ImageData, oldAnchor: ImageData, prevX: number, prevY: number, startNodes: FilledNode[], failLimit: number, testCtx?: CanvasRenderingContext2D): MotionTrackingResult {
    const visited: Set<String> = new Set<String>()
    const queue = new PriorityQueue<FilledNode>(function (a, b) {
        return b.diff - a.diff
    })

    function nodeHash(node: Node) {
        return `${node.x}${node.y}`
    }

    startNodes.forEach((filledNode: FilledNode) => {
        queue.enq(filledNode)
        visited.add(nodeHash(filledNode))
    })

    if (queue.isEmpty()) {
        Sentry.captureException("queue was empty during motion tracking... ")
        return {
            anchorX: 0,
            anchorY: 0,
            score: 0
        }
    }

    let bestDiff = queue.peek().diff
    let bestX = queue.peek().x
    let bestY = queue.peek().y

    let limit = failLimit

    while (queue.size() > 0 && limit > 0) {
        const current = queue.deq()
        for (let i = -1; i < 2; i++) {
            for (let j = -1; j < 2; j++) {

                if (i === 0 && j === 0) continue

                const newX = current.x + i
                const newY = current.y + j

                const newHash = nodeHash({x: newX, y: newY})
                if (newX < 0 || newX >= image.width ||
                    newY < 0 || newY >= image.height ||
                    visited.has(newHash)) continue

                visited.add(newHash)

                const newDiff = getDiff(image, oldAnchor, newX, newY, prevX, prevY)

                if (newDiff < bestDiff) {
                    bestX = newX
                    bestY = newY
                    bestDiff = newDiff
                    limit = failLimit
                } else {
                    limit--
                }

                if (testCtx != null) {
                    testCtx.fillStyle = "rgba(255,255,255," + newDiff / 500 + ")"
                    testCtx.fillRect(newX, newY, 1, 1)
                }

                const newNode = {
                    x: newX,
                    y: newY,
                    diff: newDiff
                }

                queue.enq(newNode)
            }
        }
    }

    return {
        anchorX: bestX,
        anchorY: bestY,
        score: bestDiff
    }
}

export function checkAllNearest(testCtx: CanvasRenderingContext2D, image: ImageData, oldAnchor: ImageData, prevX: number, prevY: number, checkSize: number, checkStep: number): MotionTrackingResult {
    let bestDiff = getDiff(image, oldAnchor, prevX, prevY, prevX, prevY)
    let bestX = prevX
    let bestY = prevY

    const checkAnchor = (x: number, y: number) => {
        const diff = getDiff(image, oldAnchor, prevX + x, prevY + y, prevX, prevY)
        if (diff < bestDiff) {
            bestX = prevX + x
            bestY = prevY + y
            bestDiff = diff
        }

        testCtx.fillStyle = "rgba(255,255,255, " + diff / 500 + ")"
        testCtx.fillRect(prevX + x, prevY + y, 1, 1)
    }

    for (let i = -checkSize / 2; i < checkSize / 2; i += checkStep) {
        for (let j = -checkSize / 2; j < checkSize / 2; j += checkStep) {
            checkAnchor(i, j)
        }
    }

    return {
        anchorX: bestX,
        anchorY: bestY,
        score: bestDiff
    }
}

function ssidDiff(imageData: ImageData, oldAnchor: ImageData, newX: number, newY: number, prevX: number, prevY: number): number {
    const newAnchor = cutNewAnchor(imageData, newX, newY, oldAnchor.width, oldAnchor.height)
    let diff = 0
    for (let i = 0; i < oldAnchor.data.length; i += 1) {
        diff += (newAnchor.data[i] - oldAnchor.data[i]) ** 2 / 100000
    }
    return diff
}


function getGrey(img: ImageData, i: number, color: number): number {
    //return img.data[i] * .299 + img.data[i + 1] * .587 + img.data[i + 2] * .114
    return img.data[i]
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function correlationDiff(imageData: ImageData, oldAnchor: ImageData, newX: number, newY: number, prevX: number, prevY: number, color: number, zeroNorm: boolean = true): number {
    const newAnchor = cutNewAnchor(imageData, newX, newY, oldAnchor.width, oldAnchor.height)
    let templateSum = 0
    let newAnchorSum = 0
    const dataLength = imageData.data.length

    for (let i = 0; i < oldAnchor.data.length; i += 1) {
        if ((i + 1) % 4 === 0) continue
        templateSum += getGrey(oldAnchor, i, color)
        newAnchorSum += getGrey(newAnchor, i, color)
    }

    const templateAvg = zeroNorm ? templateSum / (dataLength / 3) : 0
    const newAnchorAvg = zeroNorm ? newAnchorSum / (dataLength / 3) : 0

    let templateVar = 0
    let newAnchorVar = 0
    for (let i = 0; i < oldAnchor.data.length; i += 1) {
        if ((i + 1) % 4 === 0) continue
        templateVar += (getGrey(oldAnchor, i, color) - templateAvg) ** 2
        newAnchorVar += (getGrey(newAnchor, i, color) - newAnchorAvg) ** 2
    }

    let numerator = 0
    for (let i = 0; i < newAnchor.data.length; i += 1) {
        if ((i + 1) % 4 === 0) continue
        numerator += (getGrey(oldAnchor, i, color) - templateAvg) * (getGrey(newAnchor, i, color) - newAnchorAvg)
    }

    const denominator = Math.sqrt(templateVar * newAnchorVar)

    return -numerator / denominator
}


// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getChangePenalty(newX: number, newY: number, prevX: number, prevY: number, imgW: number, imgH: number): number {
    return Math.sqrt((newX - prevX) ** 2 + (newY - prevY) ** 2) / Math.sqrt(imgW ** 2 + imgH ** 2)
}

function getDiff(imageData: ImageData, oldAnchor: ImageData, newX: number, newY: number, prevX: number, prevY: number): number {
    return ssidDiff(imageData, oldAnchor, newX, newY, prevX, prevY)
    //return correlationDiff(imageData, oldAnchor, newX, newY, prevX, prevY, 0, false)
}

export function cutNewAnchor(imageData: ImageData, anchorX: number, anchorY: number, anchorWidth: number, anchorHeight: number): ImageData {
    const imgWidth = imageData.width
    const imgData = imageData.data
    const newAnchorData = new Uint8ClampedArray(anchorWidth * anchorHeight * 4)

    //console.log(imageData, anchorX, anchorY, anchorWidth, anchorHeight)

    for (let y = 0; y < anchorHeight; y++) {
        const imgY = anchorY + y
        const row = imgData.subarray(imgY * imgWidth * 4 + anchorX * 4, imgY * imgWidth * 4 + anchorX * 4 + anchorWidth * 4)
        newAnchorData.set(row, y * anchorWidth * 4)
    }


    return new ImageData(newAnchorData, anchorWidth, anchorHeight)
}