import styled from "styled-components"

export const theme = {
    dark: "#131112",
    light: "#f8fdff",
    secondary1: "#01bdf6",
    secondary2: "#017ecb",
    primary1: "#14B8B5",
    primary2: "#109391",
    active: "linear-gradient(to right, rgb(222, 222, 222), rgba(248, 248, 248, 1) 73.11%, rgba(243, 243, 243, 1))",
}

export type ITheme = typeof theme


export const BaseHint = styled.div`
  position: absolute;

  color: black;
  text-transform: none;
  font-weight: 400;
  line-height: 13px;
  text-align: left;
  border: 1px solid #939393;
  transition: opacity 0.3s, visibility 0.3s;
  opacity: 0;
  padding: 5px;
  background: white;
  box-shadow: 0 0 5px 0 rgb(190, 190, 190);
  border-radius: 6px;
  visibility: hidden;
`

export const Button = styled.button`
  background: ${props => props.theme.primary1};
  color: #fff;
  font-size: 0.6em;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 6px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  box-shadow: 1px 1px 1px 0px #929292;
  user-select: none;
  min-height: 32px;
 
  &:hover {
    background-color: ${props => props.theme.primary2};;
  }
  
  &:active {
    box-shadow: 1px 1px 1px 0px #929292 inset;
  }
  
  &:disabled {
    background-color: #afbdcc;
    box-shadow: 1px 1px 1px 0px #92929254 inset;
    cursor: not-allowed;
  }
  
  & > img {
    margin-right: 6px;
  }
`

export const SecondaryButton = styled(Button)`
  background: ${props => props.theme.secondary1};
  
  &:hover {
    background-color: ${props => props.theme.secondary2};
  }
  
   &:disabled {
    background-color: #afbdcc;
    box-shadow: 1px 1px 1px 0px #92929254 inset;
    cursor: not-allowed;
  }
`

export const ImageButton = styled.button`
  padding: 5px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.5s ease;
      border-radius: 6px;
  
  &:hover {
    background-color: #ececec;
  }
`

export const CardStyle = styled.div`
  box-shadow: 0 1px 1px rgba(0,24,75,0.11), 
              0 2px 2px rgba(0,24,75,0.11), 
              0 4px 4px rgba(0,24,75,0.11), 
              0 6px 8px rgba(0,24,75,0.11),
              0 8px 16px rgba(0,24,75,0.11);
  background: white;
  border-radius: 5px;
  background: ${props => props.theme.fg};
`

export const Success = styled.p`
  color: #2dda22;
  font-size: 2em;
  font-weight: 600;
`

export const Failure = styled.p`
  color: red;
  font-size: 2em;
  font-weight: 600;
`

export const Loader = styled.div`
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #000000;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
    /* Safari */
    @-webkit-keyframes spin {
      0% { -webkit-transform: rotate(0deg); }
      100% { -webkit-transform: rotate(360deg); }
    }
    
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
`

export const SmallLoader = styled(Loader)`
  width: 20px;
  height: 20px;
  border-width: 5px;
`
