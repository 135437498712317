import {
    addMovePointWithAnchor,
    anchorIsSelected,
    anchorToMoves,
    editCurrentMoves,
    getActiveEdit,
    getCurrentMoveIdx,
    getCurrentPosition,
    getCurrentSizeAndPosition,
    getDefaultAnchor,
    getEditIdxById,
    getEditsWithActiveAltered,
    getMaxId,
    hasAnchor
} from "../../../utils/StateHelpers"
import {
    Edit,
    EditorState,
    EditType,
    ExportState,
    Font,
    ImageEdit,
    Move,
    MoveWithAnchor,
    Position,
    TextAlignment,
    TextEdit,
    TimelineDragType,
    UploadState
} from "./types"
import {
    ADD_IMAGE,
    ADD_TEXT,
    ADD_TITLE_SPACE,
    CENTER,
    CHANGE_EXPORT_STATE,
    CHANGE_ORDER,
    CHANGE_PROGRESS_NOTE,
    DELETE_EDIT,
    DRAG,
    EDIT_TEXT,
    EditorActionTypes,
    ExportType,
    REMOVE_ALL_MOVE_POINTS,
    REMOVE_MOVE_POINT,
    SAVE_PREVIEW,
    SET_DRAGGING,
    SET_MOTION_TRACK_ACTIVE,
    SET_MOTION_TRACKING_IN_PROGRESS,
    SET_TEMPLATE,
    TOGGLE_ANIMATE,
    TOGGLE_AUTO_TRACK_ACTION,
    TOGGLE_MOTION_TRACKING,
    TOGGLE_WATERMARK
} from "../../actions/editorActionTypes"
import {
    CommonActionTypes,
    GO_BACK_IN_HISTORY,
    HANDLE_MOTION_TRACK_RESULT,
    SET_ACTIVE,
    TIMELINE_DRAG,
    UPLOAD_GIF
} from "../../actions/commonTypes"
import * as R from "ramda"
import {DragType} from "../../../components/editor/videoManipulator/types"
import {RootState} from "../index"
import {rotate} from "../../../utils/MathHelpers"
import {analytics} from "../../../firebase/firebaseHelper"


export const initialEditorState: EditorState = {
    edits: [],
    startFrame: 0,
    endFrame: 0,
    titleSpace: 0,
    active: undefined,
    selectedAnimationType: 2,
    anchorSelected: false,
    dragType: undefined,
    motionTrackingInProgress: false,
    autoTrackingInProgress: false,
    autoTrackingAverage: {
        average: 0,
        n: 0
    },
    progress: {
        note: "",
        exportProgress: {
            gif: ExportState.NONE,
            video: ExportState.NONE
        },
        uploadProgress: UploadState.NONE
    },
    exported: {},
    watermark: true
}

function generateEditStartEnd(rootState: RootState): { startFrame: number, endFrame: number } {
    return {
        startFrame: rootState.player.currentFrame !== rootState.player.videoInfo.frameCnt - 1 ? rootState.player.currentFrame : rootState.player.videoInfo.frameCnt - 2,
        endFrame: rootState.player.currentFrame < rootState.editor.endFrame ? rootState.editor.endFrame : rootState.player.videoInfo.frameCnt - 1
    }
}

function editorReducer(rootState: RootState, action: EditorActionTypes | CommonActionTypes): EditorState {
    const state = rootState.editor

    switch (action.type) {
        case ADD_IMAGE: {
            const newId = getMaxId(state.edits) + 1

            const hCenter = rootState.player.videoInfo.width / 2
            const vCenter = rootState.player.videoInfo.height / 2

            const ratio = action.height / action.width
            const height = 100
            const width = height / ratio

            const newEdit: ImageEdit = {
                id: newId,
                type: EditType.IMAGE,
                img: action.img,
                ...generateEditStartEnd(rootState),
                moves:
                    {
                        x: hCenter,
                        y: vCenter,
                        rotation: 0.0,
                        width,
                        height
                    }
            }

            return {
                ...state,
                edits: R.append(newEdit, state.edits),
                active: newId
            }
        }
        case ADD_TEXT: {

            const initialWidth = 100
            const initialHeight = 45

            const hCenter = rootState.player.videoInfo.width / 2 - initialWidth / 2
            const vCenter = rootState.player.videoInfo.height / 2 - initialHeight / 2

            const newId = getMaxId(state.edits) + 1

            const newEdit: TextEdit = {
                id: newId,
                type: EditType.TEXT,
                text: "Text",
                textAlignment: TextAlignment.CENTER,
                font: "Impact" as Font,
                borderSize: 1,
                moves:
                    {
                        x: hCenter,
                        y: vCenter,
                        rotation: 0,
                        width: initialWidth,
                        height: initialHeight
                    },
                textColor: {r: 255, g: 255, b: 255, a: 1},
                strokeColor: {r: 0, g: 0, b: 0, a: 1},
                backgroundColor: {r: 0, g: 0, b: 0, a: 0},
                padding: 0,
                ...generateEditStartEnd(rootState)
            }

            return {
                ...state,
                edits: R.append(newEdit, state.edits),
                active: newId
            }
        }

        case EDIT_TEXT: {
            if (state.active === undefined) {
                throw Error("Action not legal")
            }

            const activeEditIdx = getEditIdxById(state.edits, state.active)

            const newEdit = {
                ...state.edits[activeEditIdx],
                ...action.params
            }

            return {
                ...state,
                edits: R.update(activeEditIdx, newEdit, state.edits)
            }
        }

        case DRAG: {
            if (state.active === undefined) {
                throw Error("Action not legal")
            }

            const activeIdx = getEditIdxById(state.edits, state.active)
            const activeEdit = state.edits[activeIdx]

            // Should not happen during normal usage but let's play it safe
            if (activeEdit === undefined) return {
                ...state,
                active: undefined
            }

            const currentEditState = getCurrentSizeAndPosition(activeEdit.moves, rootState.player.currentFrame)
            const current = getCurrentSizeAndPosition(anchorIsSelected(activeEdit.moves, state.anchorSelected) ? anchorToMoves(activeEdit.moves as MoveWithAnchor[]) : activeEdit.moves, action.frameIdx)

            const centerVec = {x: current.x, y: current.y}
            const {x, y} = rotate({x: action.x, y: action.y}, -current.rotation, centerVec)

            let newX = current.x
            let newY = current.y
            let newRotation = current.rotation
            let newWidth = current.width
            let newHeight = current.height

            interface Vec {
                x: number,
                y: number
            }

            function calc(oldCorner: Vec, mouse: Vec): Vec {
                const cornerVecSize = Math.sqrt(oldCorner.x ** 2 + oldCorner.y ** 2)
                const unitVec = {x: oldCorner.x / cornerVecSize, y: oldCorner.y / cornerVecSize}
                let mouseProjectionSize = unitVec.x * mouse.x + unitVec.y * mouse.y

                if (mouseProjectionSize < 0) {
                    mouseProjectionSize = 0.1
                }

                return {
                    x: unitVec.x * mouseProjectionSize,
                    y: unitVec.y * mouseProjectionSize
                }
            }

            switch (action.dragType) {
                case DragType.RESIZE_T:
                    newHeight = (current.y - y) * 2
                    break
                case DragType.RESIZE_B:
                    newHeight = (y - current.y) * 2
                    break
                case DragType.RESIZE_L:
                    newWidth = (current.x - x) * 2
                    break
                case DragType.RESIZE_R:
                    newWidth = (x - current.x) * 2
                    break
                case DragType.RESIZE_00: {
                    const newCorner = calc({
                        x: current.startX - current.x,
                        y: current.startY - current.y
                    }, {x: x - current.x, y: y - current.y})
                    newWidth = Math.abs(newCorner.x) * 2
                    newHeight = Math.abs(newCorner.y) * 2
                    break
                }
                case DragType.RESIZE_10: {
                    const newCorner = calc({
                        x: current.startX + current.width - current.x,
                        y: current.startY - current.y
                    }, {x: x - current.x, y: y - current.y})
                    newWidth = Math.abs(newCorner.x) * 2
                    newHeight = Math.abs(newCorner.y) * 2
                    break
                }
                case DragType.RESIZE_01: {
                    const newCorner = calc({
                        x: current.startX - current.x,
                        y: current.startY + current.height - current.y
                    }, {x: x - current.x, y: y - current.y})
                    newWidth = Math.abs(newCorner.x) * 2
                    newHeight = Math.abs(newCorner.y) * 2
                    break
                }
                case DragType.RESIZE_11: {
                    const newCorner = calc({
                        x: current.startX + current.width - current.x,
                        y: current.startY + current.height - current.y
                    }, {x: x - current.x, y: y - current.y})

                    newWidth = Math.abs(newCorner.x) * 2
                    newHeight = Math.abs(newCorner.y) * 2
                    break
                }
                case DragType.ROTATE: {
                    const xDiff = action.x - current.x
                    const yDiff = action.y - current.y
                    const angle = Math.atan(yDiff / xDiff)

                    let adj = 1.5708
                    if (xDiff < 0) {
                        adj += Math.PI
                    }
                    newRotation = angle + adj
                    break
                }
                case DragType.MOVE:
                    newX = action.x
                    newY = action.y
                    break
            }

            const SIZE_THRESHOLD = 15
            const ratio = current.height / current.width

            if (newHeight < SIZE_THRESHOLD || newWidth < SIZE_THRESHOLD) {
                if (newWidth < newHeight) {
                    newWidth = SIZE_THRESHOLD
                } else {
                    newHeight = SIZE_THRESHOLD
                }
            }

            if (action.dragType === DragType.RESIZE_00 ||
                action.dragType === DragType.RESIZE_01 ||
                action.dragType === DragType.RESIZE_10 ||
                action.dragType === DragType.RESIZE_11) {
                if (newHeight === SIZE_THRESHOLD) {
                    newWidth = newHeight / ratio
                } else {
                    newHeight = newWidth * ratio
                }
            }

            newHeight = Math.round(newHeight)
            newWidth = Math.round(newWidth)
            newX = Math.round(newX)
            newY = Math.round(newY)

            const anchor = hasAnchor(activeEdit.moves) ?
                (activeEdit.moves as MoveWithAnchor[])[getCurrentMoveIdx(activeEdit.moves, action.frameIdx)].anchorPosition : undefined


            const newMoves = anchorIsSelected(activeEdit.moves, state.anchorSelected) ?
                editCurrentMoves(activeEdit.moves, currentEditState.x, currentEditState.y, currentEditState.rotation, currentEditState.width, currentEditState.height, action.frameIdx, {
                    x: newX,
                    y: newY,
                    rotation: 0,
                    width: newWidth,
                    height: newHeight
                }) :
                editCurrentMoves(activeEdit.moves, newX, newY, newRotation, newWidth, newHeight, action.frameIdx, anchor)

            const newEdit: Edit = {
                ...state.edits[activeIdx],
                moves: newMoves
            }

            return {
                ...state,
                edits: R.update(activeIdx, newEdit, state.edits)
            }
        }

        case TOGGLE_ANIMATE: {
            if (state.active === undefined) {
                throw Error("Action not legal")
            }

            const activeIdx = getEditIdxById(state.edits, state.active)
            const activeEdit = state.edits[activeIdx]

            const currentPosition = getCurrentPosition(activeEdit, rootState.player.currentFrame)

            const newMoves: Position | MoveWithAnchor[] = Array.isArray(activeEdit.moves) ?
                currentPosition :
                [{
                    ...activeEdit.moves,
                    frame: rootState.player.currentFrame,
                    anchorPosition: getDefaultAnchor(currentPosition)
                }]

            return {
                ...state,
                anchorSelected: !Array.isArray(activeEdit.moves),
                edits: R.update(activeIdx, {...activeEdit, moves: newMoves}, state.edits)
            }
        }

        case TOGGLE_MOTION_TRACKING: {
            if (state.active === undefined) {
                throw Error("Action not legal")
            }

            const activeIdx = getEditIdxById(state.edits, state.active)
            const activeEdit = state.edits[activeIdx]

            if (!Array.isArray(activeEdit.moves)) {
                throw Error("Action not legal")
            }

            const hadAnchors = hasAnchor(activeEdit.moves)
            const newMoves: Move[] | MoveWithAnchor[] = hadAnchors ?
                (activeEdit.moves as MoveWithAnchor[]).map((value: MoveWithAnchor) => {
                    return {
                        x: value.x,
                        y: value.y,
                        rotation: value.rotation,
                        width: value.width,
                        height: value.height,
                        frame: value.frame
                    }
                }) :
                (activeEdit.moves as Move[]).map((value) => {
                    return {
                        ...value,
                        anchorPosition: getDefaultAnchor(value)
                    }
                })

            return {
                ...state,
                anchorSelected: !hadAnchors,
                edits: R.update(activeIdx, {...activeEdit, moves: newMoves}, state.edits)
            }
        }

        case TOGGLE_AUTO_TRACK_ACTION: {
            return {
                ...state,
                autoTrackingInProgress: !state.autoTrackingInProgress
            }
        }

        case SET_MOTION_TRACKING_IN_PROGRESS: {
            return {
                ...state,
                // autoTrackingInProgress: track && state.autoTrackingInProgress,
                motionTrackingInProgress: true
            }
        }

        case HANDLE_MOTION_TRACK_RESULT: {
            if (state.active === undefined) {
                throw Error("Action not legal")
            }

            const realCurrentFrame = rootState.player.currentFrame + 1

            const activeEditIdx = getEditIdxById(state.edits, state.active)
            const currentEdit = state.edits[activeEditIdx]

            // Edge case in which user randomly changes pages
            if (currentEdit == null) {
                return {
                    ...state,
                    autoTrackingInProgress: false,
                    motionTrackingInProgress: false
                }
            }

            const anchorMoves = anchorToMoves(currentEdit.moves as MoveWithAnchor[])
            const editPosition = getCurrentSizeAndPosition(currentEdit.moves, realCurrentFrame - 1)
            const oldAnchor = getCurrentSizeAndPosition(anchorMoves, realCurrentFrame - 1)

            const motionTrackingResult = action.result


            const xDiff = oldAnchor.startX - motionTrackingResult.anchorX
            const yDiff = oldAnchor.startY - motionTrackingResult.anchorY

            const x = editPosition.x - xDiff
            const y = editPosition.y - yDiff

            const anchorX = oldAnchor.x - xDiff
            const anchorY = oldAnchor.y - yDiff

            if (!Array.isArray(currentEdit.moves)) {
                // Static edits do not make sense to be motion tracked
                return state
            }

            const currentMoveIdx = getCurrentMoveIdx(currentEdit.moves, realCurrentFrame)

            console.log("oldAnchor: ", oldAnchor)
            console.log("edit: ", editPosition)

            const newMoveWithAnchor: MoveWithAnchor = {
                x,
                y,
                width: editPosition.width,
                height: editPosition.height,
                rotation: editPosition.rotation,
                frame: realCurrentFrame,
                anchorPosition: {
                    x: anchorX,
                    y: anchorY,
                    width: oldAnchor.width,
                    height: oldAnchor.height,
                    rotation: oldAnchor.rotation
                }
            }

            const newEditMoves: MoveWithAnchor[] = currentEdit.moves[currentMoveIdx].frame !== realCurrentFrame ?
                addMovePointWithAnchor(currentEdit.moves as MoveWithAnchor[], newMoveWithAnchor) :
                R.adjust(currentMoveIdx, (_) => newMoveWithAnchor, currentEdit.moves as MoveWithAnchor[])

            const newEdits: Edit[] = R.update(activeEditIdx, {
                ...currentEdit,
                moves: newEditMoves
            }, state.edits)

            const currentAvg = state.autoTrackingAverage
            const autoTrackInProgress = state.autoTrackingInProgress &&
                (motionTrackingResult.score < currentAvg.average * 1.5 || currentAvg.n === 0)
            const autoTackAverage = autoTrackInProgress ? {
                n: currentAvg.n + 1,
                average: currentAvg.average + ((motionTrackingResult.score - currentAvg.average) / (currentAvg.n + 1))
            } : {n: 0, average: 0}

            return {
                ...state,
                edits: newEdits,
                motionTrackingInProgress: false,
                autoTrackingInProgress: autoTrackInProgress,
                autoTrackingAverage: autoTackAverage
            }
        }

        case REMOVE_MOVE_POINT: {
            const activeIdx = getEditIdxById(state.edits, state.active!)

            if (!Array.isArray(state.edits[activeIdx].moves)) {
                return state
            }

            const edits: Edit[] = R.adjust(
                activeIdx,
                (edit: Edit): Edit => {
                    return {
                        ...edit,
                        moves: (edit.moves as Move[]).filter(move => move.frame !== action.frameIdx)
                    }
                },
                state.edits)

            return {
                ...state,
                edits
            }
        }

        case REMOVE_ALL_MOVE_POINTS: {
            const activeIdx = getEditIdxById(state.edits, state.active!)

            if (!Array.isArray(state.edits[activeIdx].moves)) {
                return state
            }

            return {
                ...state,
                edits: R.adjust(
                    activeIdx,
                    (edit: Edit): Edit => {
                        return {
                            ...edit,
                            moves: R.take(1, edit.moves as Move[]) // Only keep the start move
                        }
                    },
                    state.edits
                )
            }
        }

        case SET_ACTIVE: {
            return {
                ...state,
                active: action.id,
                anchorSelected: false
            }
        }

        case SET_MOTION_TRACK_ACTIVE: {
            return {
                ...state,
                anchorSelected: true
            }
        }

        case SET_DRAGGING: {
            let newActive
            if (action.editId !== undefined) {
                newActive = action.editId
            } else {
                newActive = state.active
            }

            return {
                ...state,
                dragType: action.dragType,
                active: newActive
            }
        }

        case TIMELINE_DRAG: {
            const frameCnt = rootState.player.videoInfo.frameCnt

            if (state.dragType !== undefined && state.dragType !== TimelineDragType.PIN) {
                const draggedEdit = getActiveEdit(state)

                let newStart = state.startFrame
                let newEnd = state.endFrame

                if (state.dragType === TimelineDragType.LEFT && draggedEdit) {
                    draggedEdit.startFrame =
                        action.frame < 0 ?
                            0 :
                            (action.frame >= draggedEdit.endFrame ?
                                draggedEdit.endFrame - 1 :
                                action.frame)
                } else if (state.dragType === TimelineDragType.RIGHT && draggedEdit) {
                    draggedEdit.endFrame =
                        action.frame >= frameCnt ? frameCnt - 1 :
                            (action.frame <= draggedEdit.startFrame ?
                                draggedEdit.startFrame + 1 : action.frame)
                } else if (state.dragType === TimelineDragType.START) {
                    newStart = Math.min(state.endFrame - 1, Math.max(0, action.frame))
                } else if (state.dragType === TimelineDragType.END) {
                    newEnd = Math.max(state.startFrame + 1, Math.min(frameCnt - 1, action.frame))
                }

                const newEdits = state.edits.slice()
                const activeEditIdx = getEditIdxById(state.edits, state.active!)
                if (draggedEdit) {
                    newEdits[activeEditIdx] = draggedEdit
                }

                return {
                    ...state,
                    edits: newEdits,
                    startFrame: newStart,
                    endFrame: newEnd
                }
            } else {
                return state
            }
        }

        case CHANGE_ORDER: {
            const currentEditIdx = getEditIdxById(state.edits, action.id)
            const secondEditIdx = action.up ? currentEditIdx - 1 : currentEditIdx + 1
            if ((currentEditIdx === 0 && action.up) || (currentEditIdx + 1 === state.edits.length && !action.up)) {
                return state
            }

            const newEdits = state.edits.slice()
            const movedEdit1 = state.edits[currentEditIdx]
            newEdits[currentEditIdx] = state.edits[secondEditIdx]
            newEdits[secondEditIdx] = movedEdit1

            return {
                ...state,
                edits: newEdits
            }
        }

        case DELETE_EDIT: {
            return {
                ...state,
                edits: state.edits.filter((edit) => edit.id !== action.id),
                active: state.active === action.id ? undefined : state.active
            }
        }

        case CHANGE_EXPORT_STATE: {

            if (action.blob !== undefined) {
                analytics.logEvent("export_gif")
            }

            return {
                ...state,
                progress: {
                    ...state.progress,
                    note: "Exporting animation...",
                    exportProgress: {
                        gif: action.exportType === ExportType.GIF ? action.state : state.progress.exportProgress.gif,
                        video: action.exportType === ExportType.WEBM ? action.state : state.progress.exportProgress.video
                    }
                },
                exported: {
                    ...state.exported,
                    exportedVideo: action.exportType === ExportType.WEBM ? action.blob : state.exported.exportedVideo,
                    exportedGif: action.exportType === ExportType.GIF ? action.blob : state.exported.exportedGif
                }

            }
        }

        case SAVE_PREVIEW: {
            return {
                ...state,
                exported: {
                    ...state.exported,
                    preview: action.data
                }
            }
        }

        case UPLOAD_GIF: {
            return {
                ...initialEditorState,
                endFrame: action.decodedFrames ? action.decodedFrames!.length - 1 : 0,
                startFrame: 0,
                progress: {
                    ...state.progress,
                    uploadProgress: action.state
                }
            }
        }

        case CENTER: {
            const newEdits = getEditsWithActiveAltered(state, (edit) => {
                const {width, height} = getCurrentSizeAndPosition(edit.moves, rootState.player.currentFrame)
                const hCenter = rootState.player.videoInfo.width / 2 - width / 2
                const vCenter = rootState.player.videoInfo.height / 2 - height / 2
                const moveIdx = getCurrentMoveIdx(edit.moves, rootState.player.currentFrame)

                const anchor = hasAnchor(edit.moves) ?
                    {
                        ...(edit.moves as MoveWithAnchor[])[moveIdx].anchorPosition,
                        x: hCenter + width + 10,
                        y: vCenter
                    } : undefined

                const newMoves = editCurrentMoves(
                    edit.moves,
                    hCenter,
                    vCenter,
                    0,
                    width,
                    height,
                    rootState.player.currentFrame,
                    anchor
                )

                return {
                    ...edit,
                    moves: newMoves
                }
            })

            return {
                ...state,
                edits: newEdits
            }
        }

        case GO_BACK_IN_HISTORY: {
            if (rootState.history.history.length < 1) return state

            return {
                ...state,
                edits: rootState.history.history[0].edits
            }
        }

        case SET_TEMPLATE: {
            return {
                ...state,
                edits: action.edits
            }
        }

        case TOGGLE_WATERMARK: {
            return {
                ...state,
                watermark: !state.watermark
            }
        }

        case ADD_TITLE_SPACE: {
            return {
                ...state,
                titleSpace: action.height
            }
        }

        case CHANGE_PROGRESS_NOTE: {
            return {
                ...state,
                progress: {
                    ...state.progress,
                    note: action.note
                }
            }
        }


        default:
            return state
    }
}


export default editorReducer