import {
    Edit,
    ExportState,
    TextEdit,
    TimelineDragType
} from "../reducers/editor/types"
import {DragType} from "../../components/editor/videoManipulator/types"

export const ADD_IMAGE = "ADD_IMAGE"
export const ADD_TEXT = "ADD_TEXT"
export const EDIT_TEXT = "EDIT_TEXT"
export const CHANGE_POSITION = "CHANGE_POSITION"
export const DRAG = "DRAG"
export const REMOVE_MOVE_POINT = "REMOVE_MOVE_POINT"
export const REMOVE_ALL_MOVE_POINTS = "REMOVE_ALL_MOVE_POINTS"
export const TOGGLE_ANIMATE = "TOGGLE_ANIMATE"
export const TOGGLE_MOTION_TRACKING = "TOGGLE_MOTION_TRACKING"
export const SET_MOTION_TRACKING_IN_PROGRESS = "SET_MOTION_TRACKING_IN_PROGRESS"
export const ADD_MOVE_POINT = "ADD_MOVE_POINT"
export const CHANGE_ORDER = "CHANGE_ORDER"
export const DELETE_EDIT = "DELETE_EDIT"
export const SET_MOTION_TRACK_ACTIVE = "SET_MOTION_TRACK_ACTIVE"
export const SET_DRAGGING = "SET_DRAGGING"
export const TOGGLE_AUTO_TRACK_ACTION = "TOGGLE_AUTO_TRACK_ACTION"
export const CHANGE_EXPORT_STATE = "CHANGE_EXPORT_STATE"
export const SAVE_PREVIEW = "SAVE_PREVIEW"
export const CENTER = "CENTER"
export const SET_TEMPLATE = "SET_TEMPLATE"
export const TOGGLE_WATERMARK = "TOGGLE_WATERMARK"
export const ADD_TITLE_SPACE = "ADD_TITLE_SPACE"
export const SAVE_DRAG_TO_HISTORY = "SAVE_DRAG_TO_HISTORY"
export const CHANGE_PROGRESS_NOTE = "CHANGE_PROGRESS_NOTE"

interface ChangeProgressNoteAction {
    type: typeof CHANGE_PROGRESS_NOTE,
    note: string
}

interface AddTextAction {
    type: typeof ADD_TEXT,
}

interface AddImageAction {
    type: typeof ADD_IMAGE,
    img: string,
    width: number,
    height: number
}

interface EditTextAction {
    type: typeof EDIT_TEXT,
    params: Partial<TextEdit & {textSize: number}>
}

interface ChangePositionAction {
    type: typeof CHANGE_POSITION,
    x: number,
    y: number,
    frameIdx: number
}

interface DragAction {
    type: typeof DRAG,
    x: number,
    y: number,
    dragType: DragType,
    frameIdx: number
}

interface RemoveMovePointAction {
    type: typeof REMOVE_MOVE_POINT,
    frameIdx: number
}

interface RemoveAllMovePointsAction {
    type: typeof REMOVE_ALL_MOVE_POINTS
}

interface ToggleAddMovePointsAction {
    type: typeof TOGGLE_ANIMATE
}

interface ToggleMotionTrackingAction {
    type: typeof TOGGLE_MOTION_TRACKING
}

interface MotionTrackNextFrameAction {
    type: typeof SET_MOTION_TRACKING_IN_PROGRESS,
}

interface AddMovePointAction {
    type: typeof ADD_MOVE_POINT,
}

interface ChangeOrderAction {
    type: typeof CHANGE_ORDER,
    id: number,
    up: boolean
}

interface DeleteEdit {
    type: typeof DELETE_EDIT,
    id: number
}

interface SetMotionTrackActive {
    type: typeof SET_MOTION_TRACK_ACTIVE,
}

interface SetDraggingAction {
    type: typeof SET_DRAGGING,
    dragType?: TimelineDragType,
    editId?: number
}

interface StartAutoTrackAction {
    type: typeof TOGGLE_AUTO_TRACK_ACTION,
}

export enum ExportType {
    GIF,
    WEBM
}
interface ChangeExportStateAction {
    type: typeof CHANGE_EXPORT_STATE,
    state: ExportState,
    exportType: ExportType
    blob?: Blob
}

interface SavePreviewAction {
    type: typeof SAVE_PREVIEW,
    data: Blob
}

interface CenterAction {
    type: typeof CENTER
}

interface SetTemplateAction {
    type: typeof SET_TEMPLATE,
    edits: Edit[]
}

interface ToggleWatermark {
    type: typeof TOGGLE_WATERMARK
}

interface AddTitleSpace {
    type: typeof ADD_TITLE_SPACE,
    height: number
}

interface SaveDragToHistory {
    type: typeof SAVE_DRAG_TO_HISTORY,
}

export type EditorActionTypes =
    AddImageAction |
    AddTextAction |
    EditTextAction |
    ChangePositionAction |
    RemoveMovePointAction |
    RemoveAllMovePointsAction |
    ToggleAddMovePointsAction |
    ToggleMotionTrackingAction |
    MotionTrackNextFrameAction |
    AddMovePointAction |
    ChangeOrderAction |
    DeleteEdit |
    SetMotionTrackActive |
    SetDraggingAction |
    StartAutoTrackAction |
    DragAction |
    ChangeExportStateAction |
    CenterAction |
    SetTemplateAction |
    SavePreviewAction |
    ToggleWatermark |
    AddTitleSpace |
    SaveDragToHistory |
    ChangeProgressNoteAction