import * as React from "react"
import styled from "styled-components"
import skipPreviousImg from "../../../../img/skip_previous-24px.svg"
import skipNextImg from "../../../../img/skip_next-24px.svg"
import playImg from "../../../../img/play_arrow-24px.svg"
import pauseImg from "../../../../img/pause-24px.svg"
import rewind from "../../../../img/fast_rewind-24px.svg"
import ExpandButton from "./ExpandButton"
import {useDispatch, useSelector} from "react-redux"
import {setPlayerState, skipFrame} from "../../../../redux/actions/playerActions"
import {RootState} from "../../../../redux/reducers"
import {BaseHint} from "../../../../styles/sharedComponents"

const MainContainer = styled.div`
  display: flex;
  padding: 1em;
  justify-content: space-between;
  align-items: center;
`

const Container = styled.div`
  display: flex;
  position: relative;
`

interface IButtonProps {
    img: string
}

const Button = styled.button<IButtonProps>`
  all: unset;
  margin: 0 5px;
  width: 32px;
  height: 32px;
  background: url(${props => props.img}) no-repeat;
  background-size: 32px;
  transition: 0.3s;
  cursor: pointer;

  &:disabled {
    background: linear-gradient(rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.8)),
    url(${props => props.img}) no-repeat;
    background-size: 32px;
  }
`

const visibleCss = `
    opacity: 1;
    visibility: visible;
`

const ControlsHint = styled(BaseHint)`
  top: -32px;
  height: 13px;
  line-height: 19px;
`

const BackHint = styled(ControlsHint)``
const BackBtn = styled(Button)`&:hover ${BackHint}{${visibleCss}}`

const MiddleHint = styled(ControlsHint)``
const MiddleBtn = styled(Button)`&:hover ${MiddleHint}{${visibleCss}}`

const ForwardHint = styled(ControlsHint)``
const ForwardBtn = styled(Button)`&:hover ${ForwardHint}{${visibleCss}}`

const RewindButton = styled(Button)`
  background-size: 24px;
  background-position: center;
`

function ControlPanel(props: {
    expanded: boolean,
    expandHandler: () => void
}) {
    const dispatch = useDispatch()
    const startFrame = useSelector((rootState: RootState) => rootState.editor.startFrame)
    const isPlaying = useSelector((rootState: RootState) => rootState.player.playing)


    return (
        <MainContainer>
            <Container>
                <RewindButton
                    id="replayBtn"
                    img={rewind}
                    onClick={() => {
                        dispatch(setPlayerState({playing: false, currentFrame: startFrame, nextFrameScheduled: false}))
                    }}
                />
            </Container>
            <Container>
                <BackBtn
                    img={skipPreviousImg}
                    onClick={() => {
                        dispatch(skipFrame(-1))
                    }}
                    disabled={isPlaying}
                >
                    <BackHint>A</BackHint>
                </BackBtn>
                <MiddleBtn
                    img={isPlaying ? pauseImg : playImg}
                    onClick={() => {
                        dispatch(setPlayerState({playing: !isPlaying, nextFrameScheduled: false}))
                    }}
                >
                    <MiddleHint>Spacebar</MiddleHint>
                </MiddleBtn>
                <ForwardBtn
                    img={skipNextImg}
                    onClick={() => {
                        dispatch(skipFrame(1))
                    }}
                    disabled={isPlaying}
                >
                    <ForwardHint>D</ForwardHint>
                </ForwardBtn>
            </Container>
            <Container>
                <ExpandButton
                    expanded={props.expanded}
                    expandHandler={props.expandHandler}
                />
            </Container>
        </MainContainer>
    )
}

export default React.memo(ControlPanel)
