import * as React from "react"
import {useState} from "react"
import {Background, Container} from "../DialogComponents"
import styled from "styled-components"
import {Line} from "../editPanel/EditPanel"
import {Button, Failure, Loader, Success} from "../../../styles/sharedComponents"
import {UploadState} from "../../../redux/reducers/editor/types"
import {uploadFileDialog} from "../../../utils/UIHelpers"
import {useDispatch} from "react-redux"
import {addImage} from "../../../redux/actions/editorActions"

export const TextInput = styled.input`
  font-size: 1.5em;
`

const UploadButton = styled(Button)`
  margin: 12px;
  padding: 8px 16px;
`

const Divider = styled.div`
  margin: 12px;
`

export const PictureUploadDialog = (props: {showPictureUploadDialog: (show: boolean) => void}) => {
    const [uploadState, setUploadState] = useState(UploadState.NONE)
    const [url, setUrl] = useState("")
    const dispatch = useDispatch()

    function uploadUrl(url: string) {
        setUploadState(UploadState.UPLOADING)
        const img = new Image()
        img.addEventListener('load', function() {
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d')!
            canvas.height = this.naturalHeight
            canvas.width = this.naturalWidth
            ctx.drawImage(this, 0, 0)
            const dataURL = canvas.toDataURL()
            setUploadState(UploadState.UPLOADED)
            dispatch(addImage(dataURL, this.naturalWidth, this.naturalHeight))
        }, false)

        img.addEventListener('error', function() {
            setUploadState(UploadState.FAILED)
        }, false)

        img.crossOrigin = 'anonymous'
        img.src = url.startsWith('blob') ? url : 'https://cors-anywhere.herokuapp.com/' + url // TODO
    }

    function uploadFile() {
        uploadFileDialog((url, _) => uploadUrl(url))
    }

    const states = {
        [UploadState.UPLOADED]: <Success>Uploaded successfully!</Success>,
        [UploadState.FAILED]: <Failure>Upload failed. <span role={"img"} aria-label={""}>😥😱</span> Try downloading the image and uploading it via the upload button. Some sites block downloading images directly, which makes me sad. </Failure>,
        [UploadState.UPLOADING]: <Loader/>,
        [UploadState.NONE]: <React.Fragment>
            <Line>
                <label>Url:</label>
                <TextInput type="text" value={url} onChange={e => setUrl(e.target.value)}/>
                <UploadButton onClick={() => uploadUrl(url)}>Upload</UploadButton>
            </Line>
            <Divider>OR</Divider>
            <UploadButton onClick={() => uploadFile()}>Choose file</UploadButton>
        </React.Fragment>
    }

    return <Background onClick={() => props.showPictureUploadDialog(false)}>
        <Container onClick={(e) => e.stopPropagation()}>
            {states[uploadState]}
        </Container>
    </Background>
}