import * as React from "react"
import {useRef} from "react"
import styled from "styled-components"
import draggerImg from "../../../../../img/drag_handle-24px.svg"
import {useDispatch, useSelector} from "react-redux"
import upImg from "../../../../../img/arrow_drop_up-24px.svg"
import downImg from "../../../../../img/arrow_drop_down-24px.svg"
import deleteImg from "../../../../../img/delete-24px.svg"
import textImg from "../../../../../img/short_text-24px.svg"
import imageImg from "../../../../../img/image-24px.svg"
import {Edit, EditType, TextEdit, TimelineDragType} from "../../../../../redux/reducers/editor/types"
import {changeOrder, deleteEdit} from "../../../../../redux/actions/editorActions"
import {RootState} from "../../../../../redux/reducers"
import {setActive} from "../../../../../redux/actions/commonActions"

interface TimelineProps {
    row: number
    start: number
    end: number
}

const TimeLine = styled.div<TimelineProps>`
  border-radius: 5px;
  background: ${props => props.theme.secondary1};
  height: 24px;
  grid-row: ${props => props.row};
  margin: 10px 0;
  grid-column-start: ${props => props.start + 2};
  grid-column-end: ${props => props.end + 2};
  pointer-events: none;
  display: flex;
  justify-content: space-between;
`

interface ControlsProps {
    row: number
}

const Controls = styled.div<ControlsProps>`
  grid-column: 1;
  grid-row: ${props => props.row};
  display: flex;
  align-self: center;
  align-items: center;
  justify-self: center;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  pointer-events: none;
`

const Dragger = styled.div`
  width: 16px;
  height: 100%;
  background: url(${draggerImg}) no-repeat center;
  cursor: ew-resize;
  pointer-events: initial;
`

interface HighlighterProps {
    active: boolean
    row: number
    end: number
}

const Highlighter = styled.div`
  grid-column-start: 1;
  grid-column-end: ${(props: HighlighterProps) => props.end};
  grid-row: ${(props: HighlighterProps) => props.row};
  align-self: stretch;
  margin: 3px 0;
  border-radius: 3px;

  ${({active}) => !active && `
    cursor: pointer;
  `} ${({active, theme}) => active && `
    background: ${theme.active}
  `}
`

const Button = styled.button`
  all: unset;
  margin: 0 5px;
  transition: 0.3s;
  cursor: pointer;
  border-radius: 3px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.12);
  }
`

const UpDownContainer = styled.div`
  display: flex;
  flex-flow: column;
  justify-items: center;
  padding-right: 6px;
`

const btnSize = "18px"
const MoveUpBtn = styled(Button)`
  background: url(${upImg}) no-repeat;
  background-size: ${btnSize};
  width: ${btnSize};
  height: ${btnSize};
  margin: 0;
  pointer-events: all;
`
const MoveDownBtn = styled(Button)`
  background: url(${downImg}) no-repeat;
  background-size: ${btnSize};
  width: ${btnSize};
  height: ${btnSize};
  margin: 0;
  pointer-events: all;
`
const DeleteBtn = styled(Button)`
  width: 24px;
  height: 24px;
  background: url(${deleteImg}) no-repeat;
  pointer-events: all;
`

const TimeLineDescription = styled.div`
  display: flex;
  align-items: flex-end;
  color: white;
  padding-left: 4px;
  font-size: 0.8em;
  flex: auto;
`

export function EditTimeline(props: {
    edit: Edit
    idx: number
    onMouseDown: (event: React.MouseEvent, dragType: TimelineDragType, editId: number) => void
}) {

    const leftDragger = useRef(null)
    const rightDragger = useRef(null)
    const frameCnt = useSelector((state: RootState) => state.player.videoInfo.frameCnt)
    const active = useSelector((state: RootState) => state.editor.active)
    const dispatch = useDispatch()


    const edit = props.edit
    const row = props.idx + 3
    let image, label
    switch (edit.type) {
        case EditType.TEXT: {
            const textEdit = edit as TextEdit
            label = textEdit.text
            image = textImg
            break
        }
        case EditType.IMAGE: {
            label = "Image"
            image = imageImg
            break
        }
    }

    console.log("edit Id: " + edit.id)

    return (
        <React.Fragment>
            <Highlighter
                active={edit.id === active}
                end={frameCnt + 2}
                row={row}
                onClick={() => dispatch(setActive(edit.id))}
            />

            <Controls row={row}>
                <DeleteBtn
                    onClick={() => window.confirm("Delete this edit?") ? dispatch(deleteEdit(edit.id)) : undefined}
                />

                <UpDownContainer>
                    <MoveUpBtn
                        onClick={() => dispatch(changeOrder(edit.id, true))}
                    />
                    <MoveDownBtn
                        onClick={() => dispatch(changeOrder(edit.id, false))}
                    />
                </UpDownContainer>

            </Controls>
            <TimeLine
                row={row}
                start={edit.startFrame}
                end={edit.endFrame}
            >
                <Dragger
                    onDragStart={() => {
                        return false
                    }}
                    ref={leftDragger}
                    onMouseDown={(e: React.MouseEvent) => props.onMouseDown(e, TimelineDragType.LEFT, edit.id)}
                />
                <TimeLineDescription>
                    <img src={image} alt={""}/>
                    <span>{label}</span>
                </TimeLineDescription>
                <Dragger
                    onDragStart={() => {
                        return false
                    }}
                    ref={rightDragger}
                    onMouseDown={(e: React.MouseEvent) => props.onMouseDown(e, TimelineDragType.RIGHT, edit.id)}
                />
            </TimeLine>
        </React.Fragment>
    )
}
