import {Edit} from "../editor/types"
import {
    ADD_IMAGE,
    ADD_TEXT,
    CENTER,
    CHANGE_ORDER,
    DELETE_EDIT,
    EditorActionTypes,
    REMOVE_ALL_MOVE_POINTS,
    REMOVE_MOVE_POINT,
    SAVE_DRAG_TO_HISTORY,
    SET_TEMPLATE
} from "../../actions/editorActionTypes"
import {PlayerActionTypes} from "../../actions/playerTypes"
import {RootState} from "../index"
import {
    CommonActionTypes,
    GO_BACK_IN_HISTORY,
    HANDLE_MOTION_TRACK_RESULT,
    TIMELINE_DRAG
} from "../../actions/commonTypes"
import * as R from "ramda"


export interface Snapshot {
    frame: number,
    edits: Edit[]
}

export interface HistoryState {
    history: Snapshot[]
}

export const initialHistoryState: HistoryState = {
    history: []
}

export function historyReducer(rootState: RootState, action: EditorActionTypes | PlayerActionTypes | CommonActionTypes): HistoryState {
    const state = rootState.history

    switch (action.type) {
        case ADD_IMAGE:
        case ADD_TEXT:
        case REMOVE_ALL_MOVE_POINTS:
        case REMOVE_MOVE_POINT:
        case DELETE_EDIT:
        case HANDLE_MOTION_TRACK_RESULT:
        case TIMELINE_DRAG:
        case CHANGE_ORDER:
        case CENTER:
        case SAVE_DRAG_TO_HISTORY:
        case SET_TEMPLATE:
            return {
                ...state,
                history: R.prepend({edits: rootState.editor.edits, frame: rootState.player.currentFrame}, state.history)
            }

        case GO_BACK_IN_HISTORY: {
            return {
                ...state,
                history: R.tail(state.history)
            }
        }
    }

    return state
}