import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { createGlobalStyle, ThemeProvider } from "styled-components";
//import Menu from "./components/Menu";
import Menu from "./components/Menu.tsx";
import Editor from "./components/editor/Editor.tsx";
import { ITheme, theme } from "./styles/sharedComponents";
import { rootReducer } from "./redux/reducers";
import { auth } from "./firebase/firebaseHelper";
import { login, logout } from "./redux/actions/userActions";
import { Detail } from "./components/detail/Detail.tsx";
import { Templates } from "./components/templates/Templates";
import { Memes } from "./components/my-memes/memes";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { HowTo } from "./components/how-to/HowTo";

const GlobalStyle = createGlobalStyle`
    body {
        margin: 0;
        font-family: 'Tajawal', sans-serif;
        -webkit-font-smoothing: antialiased;
        background: ${(props: { theme: ITheme }) => props.theme.light};
    }
    html {
      overflow-y:scroll;
    }
`;

Sentry.init({
  dsn:
    "https://dbc7e97f78e84ab9a37de9484cd3439b@o923848.ingest.sentry.io/5935238",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const isDev: boolean =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

const middleware = [thunk, isDev && logger].filter(Boolean);
// @ts-ignore
const store = createStore(rootReducer, applyMiddleware(...middleware));

function App() {
  // @ts-ignore
  console.log("headers test: " + window.crossOriginIsolated);

  auth.onAuthStateChanged((userAuth) => {
    if (userAuth != null) {
      store.dispatch(login(userAuth));
    } else {
      store.dispatch(logout());
    }
  });

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <GlobalStyle />
          <Menu />
          <Switch>
            <Route path="/my-memes">
              <Memes />
            </Route>
            <Route path="/gif-creator/:template?" component={Editor} />
            <Route path="/tutorial">
              <HowTo />
            </Route>
            <Route path="/detail/:userId/:id">
              <Detail />
            </Route>
            <Route path="/templates">
              <Templates />
            </Route>
            <Route path="/">
              <Redirect to="/gif-creator" />
            </Route>
          </Switch>
        </Router>
      </ThemeProvider>
    </Provider>
  );
}

// ========================================

ReactDOM.render(<App />, document.getElementById("root"));
