import * as React from "react"
import {useState} from "react"
import styled from "styled-components"
import {CardStyle} from "../../../styles/sharedComponents"
import {Timeline} from "./Timeline/Timeline"
import ControlPanel from "./ControlPanel/ControlPanel"

interface IContainerProps {
    expanded: boolean
}

const Container = styled(CardStyle)`
  border-radius: ${(props: IContainerProps) => props.expanded ? "0px" : "5px"};;
  width: ${(props: IContainerProps) => props.expanded ? "" : "80%"};
  margin: auto;
  user-select: none;
`

// TODO get scroll to work again
const ScrollWrapper = styled.div`
  min-height: 200px;
  //max-height: 500px;
  border-top: 1px solid lightgrey;
  padding: 0 1em;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
`


function BottomPanel(props: {}) {
    const [expanded, setExpanded] = useState(false)

    const expandHandler = () => {
        setExpanded(!expanded)
    }

    return (
        <Container expanded={expanded}>
            <ControlPanel
                expandHandler={expandHandler}
                expanded={expanded}
            />
            <ScrollWrapper>
                <Timeline
                    expanded={expanded}
                />
            </ScrollWrapper>
        </Container>
    )
}

export default React.memo(BottomPanel)
