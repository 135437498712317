import playerReducer, {initialPlayerState} from "./player/player"
import editorReducer, {initialEditorState} from "./editor/editor"
import {PlayerActionTypes} from "../actions/playerTypes"
import {CommonActionTypes} from "../actions/commonTypes"
import {PlayerState} from "./player/types"
import {EditorState} from "./editor/types"
import {EditorActionTypes} from "../actions/editorActionTypes"
import {UserState} from "./user/types"
import {initialUserState, userReducer} from "./user/user"
import {UserActionTypes} from "../actions/userTypes"
import {historyReducer, HistoryState, initialHistoryState} from "./history/history"

interface RootStateType {
    player: Readonly<PlayerState>,
    editor: Readonly<EditorState>,
    user: Readonly<UserState>,
    history: Readonly<HistoryState>
}

export type RootState = Readonly<RootStateType>

const initialState: RootState = {
    player: initialPlayerState,
    editor: initialEditorState,
    user: initialUserState,
    history: initialHistoryState
}

export function rootReducer(state: RootState = initialState, action: PlayerActionTypes | EditorActionTypes | CommonActionTypes | UserActionTypes): RootState {
    return {
        // @ts-ignore
        player: playerReducer(state, action),
        // @ts-ignore
        editor: editorReducer(state, action),
        // @ts-ignore
        user: userReducer(state, action),
        // @ts-ignore
        history: historyReducer(state, action)
    }
}

