import {NavLink} from "react-router-dom"
import * as React from "react"
import styled from "styled-components"
import tutorialVid from "./tutorial.mp4"


const InfoContainer = styled.div`
  padding: 100px;
  font-size: 1.2em;
`

const VideoContainer = styled.div`
  max-width: 500px;
  border: 1px solid #c3c3c3;
  margin: auto;
  
  video {
    width: 100%;
  }
`

export function ShortTutorial(props: {}) {
    return (
        <InfoContainer>
            <h1>Create GIF memes:</h1>
            <p>Start by
                uploading a
                GIF by clicking the "New Project" button. Alternatively, you can
                choose a gif from <NavLink to="/templates" activeClassName="active">templates</NavLink> to try it out.
            </p>

            <p>This tool provides the following capabilities:</p>
            <ul>
                <li>Add text</li>
                <li>Add images</li>
                <li>Cut video beginning and end</li>
                <li>Motion track edits</li>
                <li>Export and share gifs</li>
            </ul>
            <VideoContainer>
                <video src={tutorialVid} controls autoPlay loop/>
            </VideoContainer>
        </InfoContainer>)
}