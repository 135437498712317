import {Button, SmallLoader, Success} from "../../../../styles/sharedComponents"
import templateImg from "../../../../img/post_add-24px.svg"
import * as React from "react"
import {ButtonsContainer, Label, Title, UploadingState} from "./ExportDialog"
import {Edit} from "../../../../redux/reducers/editor/types"
import {auth, previewsRef, templatesDb, templatesRef, userTemplates} from "../../../../firebase/firebaseHelper"
import styled from "styled-components"
import {useSelector} from "react-redux"
import {RootState} from "../../../../redux/reducers"
import {useRef, useState} from "react"
import {makeId} from "../../../../utils/CommonHelper"

const PublishButton = styled(Button)`
  margin: 12px;
  font-size: 0.7em;
`

function _publish(preview: Blob, originalGifData: Uint8Array, title: string, setUploading: (uploading: UploadingState) => void, edits: Edit[]) {
    if (!auth.currentUser) return
    setUploading(UploadingState.UPLOADING)

    try {
        const original = new Blob([originalGifData], {
            type: "image/gif"
        })

        const userId = auth.currentUser.uid
        const id = makeId(6)

        // TODO start with file upload
        const templateRef = templatesRef.child(userId).child(id).put(original, {cacheControl: "public,max-age=604800,immutable"})
        const previewRef = previewsRef.child(userId).child(id).put(preview, {contentType: "image/jpeg", cacheControl: "public,max-age=604800,immutable"})

        const dbDummyPromise = templatesDb.doc(userId).set({uid: userId})
        const dbPromise = userTemplates(userId).doc(id).set({edits, id, title, userId})

        Promise.all([templateRef, previewRef, dbPromise, dbDummyPromise])
            .then(_ => {
                setUploading(UploadingState.DONE)
            })
    } catch (e) {
        console.log(e)
        window.alert("Something went wrong. Sorry.")
        setUploading(UploadingState.NONE)
    }

}


export function PublishTemplate(props: {}) {
    const textInput = useRef<HTMLInputElement>(null)
    const [uploadState, setTemplateUploading] = useState(UploadingState.NONE)
    const preview = useSelector((state: RootState) => state.editor.exported.preview)
    const originalGifData = useSelector((state: RootState) => state.player.originalGif)
    const edits = useSelector((state: RootState) => state.editor.edits)

    return <React.Fragment>
        <hr style={{width: "100%"}}/>
        <Title>Let's share it as template with everyone!</Title>
        {
            uploadState === UploadingState.NONE &&
            <Label>
                Title:
                <input type={"text"} ref={textInput}/>
            </Label>
        }
        {
            uploadState === UploadingState.DONE &&
            <Success>Template was successfully uploaded!</Success>
        }
        <ButtonsContainer>
            {uploadState === UploadingState.NONE &&
            <PublishButton
                onClick={() => _publish(preview!, originalGifData!, textInput.current!.value, setTemplateUploading, edits)}>
                <img src={templateImg} alt="publish template"/>
                Publish as template
            </PublishButton>
            }
            {uploadState === UploadingState.UPLOADING &&
            <SmallLoader/>
            }
        </ButtonsContainer>
    </React.Fragment>
}