import * as React from "react"
import styled from "styled-components"
import {connect, ConnectedProps} from "react-redux"
import {RootState} from "../../../../../redux/reducers"
import orderUpImg from "../../../../../img/flip_to_front-24px.svg"

const Container = styled.div`
  min-width: 140px; // Set width to avoid "jumping" when adding digit
  grid-column-start: 1;
  grid-row-start: 2;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
type Props = ConnectedProps<typeof connector>

function Info(props: Props) {
    return (
        <Container>
            Frame:&nbsp;{props.currentFrame + 1}&nbsp;/&nbsp;{props.frameCnt}
            <div>
                <img src={orderUpImg} style={{marginRight: '2px'}} alt={""}/>
            </div>
        </Container>
    )
}

const connector = connect((state: RootState) => ({
    currentFrame: state.player.currentFrame,
    frameCnt: state.player.videoInfo.frameCnt
}))

export default connector(Info)