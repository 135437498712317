import * as React from "react"
import styled from "styled-components"
import {CardStyle} from "../../styles/sharedComponents"
import {NavLink} from "react-router-dom"
import tutorial1 from "./assets/tutorial1.mp4"
import tutorial2 from "./assets/tutorial2.mp4"
import tutorial3 from "./assets/tutorial3.mp4"
import tutorial5 from "./assets/tutorial5.mp4"
import exportedMeme from "./assets/meme.gif"

const Container = styled(CardStyle)`
  min-width: 260px;
  width: 50%;
  padding: 30px;
  max-width: 700px;

  & video, img {
    margin: auto;
    display: block;
    width: 100%;
    max-width: 300px;
  }

  margin: 50px auto 100px;
`

export function HowTo() {
    return <Container>
        <h1>How to create GIF memes</h1>
        <h2>Memes from template</h2>
        <p>
            The easier way to create a gif meme is to use a template:
        </p>
        <ul>
            <li>Select an template from the <NavLink to={"templates"}>list</NavLink></li>
            <li>Change texts by clicking on them either directly, or by selecting the edits from timeline</li>
            <li>Export GIF</li>
        </ul>
        <h2>Creating gif memes from scratch</h2>
        <h3>Step 1: Find the gif</h3>
        <p>The first thing you want to do is to find the gif you want to meme-fy. Right-click on the gif and copy its URL:
        </p>
        <video src={tutorial1} controls/>

        <h3>Upload the gif (or a video) into the editor</h3>
        <p>
            Paste the URL and click UPLOAD. Note that some website forbid direct loading of gifs. In that case, download the file and upload it from your computer using the CHOOSE FILE button.
        </p>
        <video src={tutorial2} controls/>

        <h3>Add edits to your animation</h3>
        <p>Add texts and images. Edits can be rotated, resized, animated, motion tracked and adjusted in various other ways.</p>

        <video src={tutorial3} controls/>

        <h3>Export your meme!</h3>
        <p>Simply click the export button. GIF should be created and modern browser should also generate a video.</p>
        <video src={tutorial5} controls/>

        <h3>Share it with the world!</h3>
        <img src={exportedMeme} alt={""}/>



    </Container>
}