import styled from "styled-components"

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  background: rgb(55,55,55);
  background: radial-gradient(circle,rgb(0 0 0 / 56%) 2%,rgb(0 0 0 / 20%) 100%);
`

export const Container = styled.div`
  z-index: 10;
  position: relative;
  background: white;
  border-radius: 12px;
  margin: 70px auto auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  flex-direction: column;
  max-width: 600px;
`