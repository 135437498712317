import {
    PlayerActionTypes,
    PlayerStateUpdate, SAVE_ORIGINAL_DATA,
    SET_FRAME,
    SET_PLAYER_STATE,
    SKIP_FRAME
} from "./playerTypes"

export function setPlayerState(playerState: PlayerStateUpdate): PlayerActionTypes {
    return {
        type: SET_PLAYER_STATE,
        playerState
    }
}

export function skipFrame(skipCnt: number): PlayerActionTypes {
    return {
        type: SKIP_FRAME,
        skipCnt
    }
}

export function setFrame(frame: number) {
    return {
        type: SET_FRAME,
        frame
    }
}

export function saveOriginalData(data: Uint8Array): PlayerActionTypes {
    return {
        type: SAVE_ORIGINAL_DATA,
        data
    }
}
