import {Frame} from "omggif"

export enum DragType {
    MOVE,
    RESIZE_L,
    RESIZE_R,
    RESIZE_T,
    RESIZE_B,
    RESIZE_00,
    RESIZE_01,
    RESIZE_10,
    RESIZE_11,
    ROTATE
}

export interface Dragger {
    x: number,
    y: number,
    startX: number,
    startY: number,
    width: number,
    height: number,
    outer: boolean
    type: DragType
}

export interface Drag {
    prevPosition?: {
        x: number,
        y: number,
        width: number,
        height: number,
        rotation: number
    }
    type: DragType
    xOffset: number
    yOffset: number
}

export interface SimpleDecodedFrame {
    frameInfo: Frame
    pixels: Uint8ClampedArray
}

export interface DecodedFrame extends SimpleDecodedFrame{
    buffer: HTMLCanvasElement
}
