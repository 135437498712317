import * as React from "react"
import {useState} from "react"
import styled from "styled-components"
import {ChromePicker, ColorResult} from "react-color"
import {Color} from "../../../redux/reducers/editor/types"
import {useDispatch} from "react-redux"
import {saveHistory} from "../../../redux/actions/editorActions"

interface ButtonProps {
    btnColor: Color
}

const Button = styled.div<ButtonProps>`
  border: 1px solid black;
  width: 30px;
  height: 20px;
  background: rgba(
    ${props => props.btnColor.r}, 
    ${props => props.btnColor.g},
    ${props => props.btnColor.b},
    ${props => props.btnColor.a}
   );
`
const Popover = styled.div`
  position: absolute;
  z-index: 2;
`

const Cover = styled.div`
  position: fixed;
  inset: 0;
`

const checkersColor = 'rgb(210,210,210)'
const TransparencyIndicator = styled.div`
  // A little wild but seems to work great
  background-image: 
          linear-gradient(45deg, ${checkersColor} 25%, transparent 25%), 
          linear-gradient(-45deg, ${checkersColor} 25%, transparent 25%),
          linear-gradient(45deg, transparent 75%, ${checkersColor} 75%),
          linear-gradient(-45deg, transparent 75%, ${checkersColor} 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
  width: fit-content;
`

export function ColorPicker(props: { color: Color, onSetColor: (color: Color) => void}) {
    const [displayColorPicker, setDisplayColorPicker] = useState(false)
    const dispatch = useDispatch()

    function handleClick() {
        if (!displayColorPicker) {
            dispatch(saveHistory())
        }
        setDisplayColorPicker(!displayColorPicker)
    }

    function handleChange(color: ColorResult) {
        const a = color.rgb.a ? color.rgb.a : 0
        props.onSetColor({
            ...color.rgb,
            a
        })
    }

    function handleClose() {
        setDisplayColorPicker(false)
    }

    return (
        <TransparencyIndicator>
            <Button btnColor={props.color} onClick={handleClick}/>
            {displayColorPicker ?
                <Popover>
                    <Cover onClick={handleClose}/>
                    <ChromePicker color={props.color} onChange={handleChange}/>
                </Popover> :
                null}
        </TransparencyIndicator>
    )
}
