import React from "react"
import styled from "styled-components"
import rightImg from "../../../../../img/arrow_forward-24px.svg"
import leftImg from "../../../../../img/arrow_back-24px.svg"
import {useSelector} from "react-redux"
import {RootState} from "../../../../../redux/reducers"
import {TimelineDragType} from "../../../../../redux/reducers/editor/types"

const Dragger = styled.div<{ column: number, start: number }>`
  position: absolute;
  background:  url("${props => props.start ? rightImg : leftImg}") no-repeat;
  width: 24px;
  grid-column: ${props => props.column + 2};
  background-position-x: ${props => props.start ? "-3px" : "3px"};
  left: ${props => props.start ? "0px" : "-23px"};
    pointer-events: none;
  z-index: 3;
`

const VerticalLine = styled.div<{ start: number, height: number }>`
  height: ${props => `${props.height}px`};
  background: black;
  width: 1px;
  margin-top: -20px;
  float: ${props => props.start ? "left" : "right"};
  z-index: 3;
`

const ClickablePart = styled.div`
  width: 24px;
  height: 24px;
    cursor: ew-resize;
  pointer-events: initial;
  z-index: 3;
`

const UnusedHider = styled.div<{start: number, end: number, row: number, left: boolean}>`
  grid-column-start: ${props => props.start};
  grid-column-end: ${props => props.end};
  grid-row-start: 3;
  grid-row-end: ${props => props.row};
  background: #efefefcf;
  background: ${
    props => props.left ?
        'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.48363095238095233) 100%);' : 
            'linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.48363095238095233) 100%);'
  }
  z-index: 2;
  display: ${props => props.start === props.end ? 'none' : 'block'};
  border-left: ${props => props.left ? '1px solid rgb(228, 228, 228)' : 'none'};
`

function DraggerCmp(props: { column: number, start: boolean, height: number, onMouseDown: (event: React.MouseEvent, dragType: TimelineDragType, editId?: number) => void }) {
    //https://stackoverflow.com/questions/49784294/warning-received-false-for-a-non-boolean-attribute-how-do-i-pass-a-boolean-f
    return <Dragger
        column={props.column}
        start={props.start ? 1 : 0}>
        <ClickablePart
            onMouseDown={(e: React.MouseEvent) => props.onMouseDown(e, props.start ? TimelineDragType.START : TimelineDragType.END)}>
        </ClickablePart>
        <VerticalLine
            start={props.start ? 1 : 0}
            height={props.height}
            onDragStart={() => false}
        />
    </Dragger>
}

export function VideoLength(props: { onMouseDown: (event: React.MouseEvent, dragType: TimelineDragType, editId?: number) => void }) {
    const startFrame = useSelector((state: RootState) => state.editor.startFrame)
    const endFrame = useSelector((state: RootState) => state.editor.endFrame)
    const editsCnt = useSelector((state: RootState) => state.editor.edits.length)
    const frameCnt = useSelector((state: RootState) => state.player.videoInfo.frameCnt)

    return <React.Fragment>
        <UnusedHider start={2} end={startFrame + 2} row={editsCnt + 3} left={true}/>
        <DraggerCmp column={startFrame} start={true} height={editsCnt * 44 + 44} onMouseDown={props.onMouseDown}/>
        <DraggerCmp column={endFrame} start={false} height={editsCnt * 44 + 44} onMouseDown={props.onMouseDown}/>
        <UnusedHider start={endFrame +2} end={frameCnt + 2} row={editsCnt + 3} left={false}/>
    </React.Fragment>
}