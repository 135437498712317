export interface Vector {
    x: number,
    y: number
}

export interface Matrix22 {
    x0y0: number,
    x0y1: number,
    x1y0: number,
    x1y1: number
}

export function matMul2d(mat: Matrix22, vec: Vector): Vector {
    return {
        x: mat.x0y0 * vec.x + mat.x1y0 * vec.y,
        y: mat.x0y1 * vec.x + mat.x1y1 * vec.y
    }
}

export function addVec2d(vec0: Vector, vec1: Vector): Vector {
    return {
        x: vec0.x + vec1.x,
        y: vec0.y + vec1.y,
    }
}

export function negVec(vec: Vector): Vector {
    return {
        x: -vec.x,
        y: -vec.y,
    }
}

export function rotate(vec: Vector, rotation: number, centerVec: Vector): Vector {
    const matrix = createRotationMatrix(rotation)
    const centered = addVec2d(vec, negVec(centerVec))
    const rotated = matMul2d(matrix, centered)
    return addVec2d(rotated, centerVec)
}

export function createRotationMatrix(rotation: number): Matrix22 {
    return {
        x0y0: Math.cos(rotation),
        x0y1: Math.sin(rotation),
        x1y0: -Math.sin(rotation),
        x1y1: Math.cos(rotation),
    }
}

export function ctg(x: number) { return 1 / Math.tan(x); }