import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/analytics"
import "firebase/storage"


//const isDev = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
//const firebaseConfig = isDev ? {
//    apiKey: "AIzaSyB4em71kH-LuJpSSeQSNzl8Hg3MOQgeOAo",
//    authDomain: "gifmemes-dev.firebaseapp.com",
//    databaseURL: "https://gifmemes-dev.firebaseio.com",
//    projectId: "gifmemes-dev",
//    storageBucket: "gifmemes-dev.appspot.com",
//    messagingSenderId: "980092915001",
//    appId: "1:980092915001:web:df9b7093f3293f042dece8",
//    measurementId: "G-ZTX4ERYC9S"
//} : {
//    apiKey: "secret",
//    authDomain: "secret",
//    databaseURL: "secret",
//    projectId: "secret",
//    storageBucket: "secret",
//    messagingSenderId: "secret",
//    appId: "secret",
//    measurementId: "secret"
//};

const firebaseConfig = {
    apiKey: "secret",
    authDomain: "secret",
    databaseURL: "secret",
    projectId: "secret",
    storageBucket: "secret",
    messagingSenderId: "secret",
    appId: "secret",
    measurementId: "secret"
};
firebase.initializeApp(firebaseConfig)

export const analytics = firebase.analytics()

export const auth = firebase.auth()

export const db = firebase.firestore()

export const templatesDb = db.collection("templates")
export function userTemplates(userId: string) {
    return templatesDb.doc(userId).collection('userTemplates')
}

export type FirebaseReference = firebase.storage.Reference

export const storageRef = firebase.storage().ref();
export const exportedRef = storageRef.child('exported')
export const templatesRef = storageRef.child('templates')
export const previewsRef = storageRef.child('previews')