import {LOGIN, LOGOUT, UserActionTypes} from "./userTypes"
import firebase from "firebase/app"


export function login(user: firebase.User): UserActionTypes {
    return {
        type: LOGIN,
        user
    }
}

export function logout(): UserActionTypes {
    return {
        type: LOGOUT,
    }
}

