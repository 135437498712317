import React from "react"
import {LoggedIn} from "./loggedIn"
import {useSelector} from "react-redux"
import {RootState} from "../../redux/reducers"
import {LoggedOut} from "./loggedOut"
import styled from "styled-components"
import {UserState} from "../../redux/reducers/user/types"
import {Loader} from "../../styles/sharedComponents"

const UserLoader = styled(Loader)`
  width: 20px;
  height: 20px;
  border-width: 5px;
  margin: auto 12px;
`
const Container = styled.div`
  justify-content: center;
  display: flex;
  height: 32px;
  width: 190px;
`

function _getContent(userState: UserState) {
    if (userState.loading) {
        return  <UserLoader/>
    }else if (userState.user == null) {
        return LoggedOut()
    } else {
        return LoggedIn(userState.user)
    }
}

export function User() {
    const userState = useSelector((state: RootState) => state.user)

    return <Container>{_getContent(userState)}</Container>
}