import {VideoManipulator} from "../components/editor/videoManipulator/videoManipulator"

export function uploadFileDialog(callback: (url: string, name: string) => void) {
    console.log("started file upload")
    const tmp = document.createElement("input") as HTMLInputElement
    tmp.setAttribute("type", "file")
    tmp.addEventListener("change", function () {
        console.log("file callback " + this.files)
        if (this.files && this.files[0]) {
            console.log(this.files[0])
            const url = URL.createObjectURL(this.files[0])
            callback(url, this.files[0].name)
        }

        document.body.removeChild(tmp)
    })

    console.log("clicking")
    tmp.setAttribute("style", "visibility: hidden")
    tmp.click()
    // Safari hack
    document.body.appendChild(tmp)
}

export function setUpCanvasTouchAndMouse(canvas: HTMLCanvasElement, playbackController: VideoManipulator) {
    function preventDefault(e: TouchEvent) {
        if (e.cancelable) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    canvas.addEventListener("touchstart", function (e) {
        preventDefault(e)

        const mouseEvent = new MouseEvent("mousedown", {
            clientX: e.touches[0].clientX,
            clientY: e.touches[0].clientY
        });
        canvas.dispatchEvent(mouseEvent);
    }, { passive: false });

    canvas.addEventListener("touchend", function (e) {
        preventDefault(e)

        const mouseEvent = new MouseEvent("mouseup", {});
        canvas.dispatchEvent(mouseEvent);
    }, { passive: false });

    canvas.addEventListener("touchmove", function (e) {
        preventDefault(e)

        const touch = e.touches[0];
        const mouseEvent = new MouseEvent("mousemove", {
            clientX: touch.clientX,
            clientY: touch.clientY
        });
        canvas.dispatchEvent(mouseEvent);
    }, { passive: false });

    // Prevent scrolling when touching the canvas
    // document.body.addEventListener("touchstart", function (e) {
    //     if (e.target == canvas) {
    //         e.preventDefault();
    //     }
    // }, false);
    // document.body.addEventListener("touchend", function (e) {
    //     if (e.target == canvas) {
    //         e.preventDefault();
    //     }
    // }, false);
    // document.body.addEventListener("touchmove", function (e) {
    //     console.log(e.target)
    //     if (e.target == canvas) {
    //         e.preventDefault();
    //     }
    // }, false);

    canvas.addEventListener("mousedown", (e) => {
        const rect = canvas.getBoundingClientRect()
        playbackController.handleMouseDown(
            e.clientX - rect.left,
            e.clientY - rect.top,
            e.altKey
        )
    })

    canvas.addEventListener("mousemove", (e) => {
        const rect = canvas.getBoundingClientRect()
        playbackController.handleMouseMove(
            e.clientX - rect.left,
            e.clientY - rect.top
        )
    })

    canvas.addEventListener("mouseup", (e) => {
        const rect = canvas.getBoundingClientRect()
        playbackController.handleMouseUp(
            e.clientX - rect.left,
            e.clientY - rect.top
        )
    })
}