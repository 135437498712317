import {
    detectAnchor,
    detectCornerType,
    detectEdit,
    getBorder,
    getCursor,
    getMultilineText,
    isEditVisible
} from "./editorCanvasHelpers"
import {
    getActiveEdit,
    getCurrentMoveIdx,
    getCurrentSizeAndPosition,
    hasAnchor, anchorToMoves, anchorIsSelected, fontToWeb
} from "../../../utils/StateHelpers"
import {
    changeExportState,
    drag, saveHistory,
    savePreviewAction,
    setMotionTrackActive
} from "../../../redux/actions/editorActions"
import {
    EditorState,
    EditType,
    ExportState,
    ImageEdit, Move, MoveWithAnchor, Position,
    TextAlignment,
    TextEdit
} from "../../../redux/reducers/editor/types"
import {DecodedFrame, Drag, DragType} from "./types"
import {PlayerState, VideoInfo} from "../../../redux/reducers/player/types"
import {Dispatch} from "redux"
import {ERROR} from "./decodingWorker"
import {setPlayerState} from "../../../redux/actions/playerActions"
import {CanvasRecorder} from "./recorder"
import {rotate, Vector} from "../../../utils/MathHelpers"
import {setActive} from "../../../redux/actions/commonActions"
import GIF from "gif.js.optimized"
import {ExportType} from "../../../redux/actions/editorActionTypes"


export function loadGif(url: string): Promise<Uint8Array | undefined> {
    return new Promise(((resolve) => {
        const req = new XMLHttpRequest()
        req.responseType = "arraybuffer"
        req.timeout = 1000 * 15 // 15 seconds
        req.open("GET", url, true)
        req.onload = () => {
            const gifData = new Uint8Array(req.response)
            resolve(gifData)
        }

        const abort = () => {
            resolve(undefined)
        }

        req.ontimeout = abort
        req.onerror = abort

        req.send()
    }))
}

export function decodeGif(gifData: Uint8Array): Promise<{ decodedFrames: DecodedFrame[], gifInfo: VideoInfo }> {
    return new Promise(((resolve, reject) => {
        const decodeWorker = new Worker("./decodingWorker", {type: "module"})
        decodeWorker.postMessage({type: "gifmemes", gifData})

        decodeWorker.onmessage = function (e) {
            decodeWorker.terminate()
            if (e.data === ERROR) {
                reject("decoding failed")
            }

            const decodedFrames = e.data.frames
            const gifInfo = e.data.gifInfo
            resolve({decodedFrames, gifInfo})
        }
    }))
}

export function createBuffer(frame: DecodedFrame, width: number, height: number): HTMLCanvasElement {
    const bufferCanvas = document.createElement("canvas")
    const bufferContext = bufferCanvas.getContext("2d")!
    bufferCanvas.width = width
    bufferCanvas.height = height
    const imageData = bufferContext.createImageData(width, height)
    imageData.data.set(frame.pixels)
    bufferContext.putImageData(imageData, 0, 0)
    return bufferCanvas
}

export class VideoManipulator {
    canvas: HTMLCanvasElement
    ctx: CanvasRenderingContext2D
    getCurrentEditorState: () => EditorState
    getCurrentPlayerState: () => PlayerState
    dispatch: Dispatch
    drag?: Drag
    recorder: CanvasRecorder
    imagesCache: Map<number, HTMLImageElement>
    helperCanvas: HTMLCanvasElement
    helperCtx: CanvasRenderingContext2D

    constructor(canvas: HTMLCanvasElement, getCurrentEditorState: () => EditorState, getCurrentPlayerState: () => PlayerState, dispatch: Dispatch) {
        this.canvas = canvas
        this.ctx = canvas.getContext("2d")!
        this.getCurrentEditorState = getCurrentEditorState
        this.getCurrentPlayerState = getCurrentPlayerState
        this.dispatch = dispatch
        this.recorder = new CanvasRecorder(canvas)
        this.imagesCache = new Map<number, HTMLImageElement>()
        this.helperCanvas = document.createElement("CANVAS") as HTMLCanvasElement
        this.helperCtx = this.helperCanvas.getContext("2d")!
    }

    updatePlayer() {
        const playerState = this.getCurrentPlayerState()
        this.drawFrame(playerState.currentFrame)
    }

    _setDrag = (x: number, y: number, type: DragType, moves: Position | Move[]) => {
        this.dispatch(saveHistory())


        const current = getCurrentSizeAndPosition(moves, this.getCurrentPlayerState().currentFrame)
        this.drag = {
            xOffset: x - current.x,
            yOffset: y - current.y,
            type: type,
            prevPosition: {
                height: current.height,
                width: current.width,
                x: current.x,
                y: current.y,
                rotation: current.rotation
            }
        }
    }

    handleMouseDown(xPos: number, yPos: number, altKey: boolean) {
        const {x, y} = this._adjustOrigin(xPos, yPos)

        const state = this.getCurrentEditorState()
        const activeEdit = getActiveEdit(state)
        const playerState = this.getCurrentPlayerState()

        // Check for corners and anchor
        if (activeEdit != null) {
            const cornerType = detectCornerType(x, y, anchorIsSelected(activeEdit.moves, state.anchorSelected) ? anchorToMoves(activeEdit.moves as MoveWithAnchor[]) : activeEdit.moves, playerState.currentFrame, state.anchorSelected)
            if (cornerType !== undefined) { // Clicked corner, drag
                this._setDrag(x, y, cornerType, anchorIsSelected(activeEdit.moves, state.anchorSelected) ? anchorToMoves(activeEdit.moves as MoveWithAnchor[]) : activeEdit.moves)
                return
            } else if (hasAnchor(activeEdit.moves)) { // Check for click on anchor
                if ((altKey || detectEdit(x, y, state.edits, playerState.currentFrame) === null) && detectAnchor(x, y, activeEdit, playerState.currentFrame)) {
                    this.dispatch(setMotionTrackActive())
                    this._setDrag(x, y, DragType.MOVE, anchorToMoves(activeEdit.moves as MoveWithAnchor[]))
                    return
                }
            }
        }

        // Otherwise clicked inside or outside edit
        let currentEdit = detectEdit(x, y, state.edits, playerState.currentFrame)
        this.dispatch(setActive(currentEdit?.id))

        if (currentEdit !== null) {
            this._setDrag(x, y, DragType.MOVE, currentEdit.moves)
        }

        this.handleMouseMove(xPos, yPos)
    }

    handleMouseUp(xPos: number, yPos: number) {
        const {x, y} = this._adjustOrigin(xPos, yPos)
        this.drag = undefined
        const playerState = this.getCurrentPlayerState()
        this.canvas.style.cursor = getCursor(x, y, this.getCurrentEditorState(), playerState.currentFrame, this.drag)
    }

    handleMouseMove(xPos: number, yPos: number) {
        const state = this.getCurrentEditorState()
        const playerState = this.getCurrentPlayerState()

        if (state.active == null) {
            this.drag = undefined
        }

        const {x, y} = this._adjustOrigin(xPos, yPos)
        const cursor = getCursor(x, y, state, playerState.currentFrame, this.drag)
        this.canvas.style.cursor = cursor
        console.log(cursor)

        // TODO move all logic into reducer
        if (this.drag !== undefined) {
            if (this.drag.type === DragType.MOVE) {
                this.dispatch(drag(this.drag.type, x - this.drag.xOffset, y - this.drag.yOffset, playerState.currentFrame))
            } else {
                this.dispatch(drag(this.drag.type, x, y, playerState.currentFrame))
            }

            //this.dispatch(addMovePoint())
        }
    }

    _adjustOrigin(x: number, y: number): {x: number, y: number} {
        const editorState = this.getCurrentEditorState()

        return {
            x,
            y: y - editorState.titleSpace
        }
    }

    drawFrame(frameIdx: number, exportCtx?: CanvasRenderingContext2D, forceIsExporting: boolean = false) {
        const editorState = this.getCurrentEditorState()
        const playerState = this.getCurrentPlayerState()

        if (playerState.decodedFrames == null) {
            return
        }

        const frame = playerState.decodedFrames[frameIdx]
        const currentEdit = getActiveEdit(editorState)

        if (frame === undefined) {
            return
        }

        const ctx = exportCtx ?? this.ctx

        ctx.canvas.width = playerState.videoInfo.width
        ctx.canvas.height = playerState.videoInfo.height + editorState.titleSpace

        const isExporting =
            editorState.progress.exportProgress.gif === ExportState.RUNNING ||
            editorState.progress.exportProgress.video === ExportState.RUNNING ||
            forceIsExporting

        ctx.fillStyle = 'white'
        ctx.fillRect(0, 0, playerState.videoInfo.width, editorState.titleSpace)

        ctx.save()
        ctx.translate(0, editorState.titleSpace)
        ctx.drawImage(frame.buffer, 0, 0)

        // Draw motion tracking anchor
        if (!isExporting &&
            currentEdit != null &&
            hasAnchor(currentEdit.moves) &&
            Array.isArray(currentEdit.moves) &&
            isEditVisible(currentEdit, playerState.currentFrame)) {
            const anchor = getCurrentSizeAndPosition(anchorToMoves(currentEdit.moves as MoveWithAnchor[]), playerState.currentFrame)

            const vLen = (anchor.height - 6) / 3
            const hLen = (anchor.width - 6) / 3
            const startX = anchor.startX +3
            const startY = anchor.startY +3

            const drawAnchor = (color: string, width: number) => {
                const drawAnchorSide = (hDir: number, vDir: number, x: number, y: number) => {
                    ctx.beginPath()
                    ctx.moveTo(x, y)
                    ctx.lineTo(x + hDir * hLen - 0.5, y + vDir * vLen - 0.5)
                    ctx.stroke()
                    ctx.beginPath()
                    ctx.moveTo(x + 2 * hDir * hLen - 0.5, y + 2 * vDir * vLen - 0.5)
                    ctx.lineTo(x + 3 * hDir * hLen - 0.5, y + 3 * vDir * vLen - 0.5)
                    ctx.stroke()
                }

                ctx.strokeStyle = color
                ctx.lineWidth = width

                drawAnchorSide(1, 0, startX, startY)
                drawAnchorSide(0, 1, startX + 3 * hLen, startY)
                drawAnchorSide(-1, 0, startX + 3 * hLen, startY + 3 * vLen)
                drawAnchorSide(0, -1, startX, startY + 3 * vLen)
            }

            drawAnchor("white", 3)
            drawAnchor("rgb(0,115,255)", 1)
        }

        // Draw edits
        const edits = editorState.edits
        for (let i = edits.length - 1; i >= 0; i--) {
            const edit = edits[i]
            if (!isEditVisible(edit, frameIdx)) {
                continue
            }

            const {x, y, width, height, rotation} = getCurrentSizeAndPosition(edit.moves, frameIdx)

            switch (edit.type) {
                case EditType.TEXT: {
                    console.log("here")
                    const textEdit = edit as TextEdit
                    const {lines, textSize} = getMultilineText(ctx, edit as TextEdit, playerState.currentFrame)

                    const adjWidth = width + textEdit.padding * 2
                    const adjHeight = height + textEdit.padding * 2

                    ctx.save()
                    ctx.translate(x, y)
                    ctx.rotate(rotation)

                    ctx.fillStyle = `rgba(${textEdit.backgroundColor.r}, ${textEdit.backgroundColor.g}, ${textEdit.backgroundColor.b}, ${textEdit.backgroundColor.a})`
                    ctx.fillRect(-adjWidth / 2, -adjHeight / 2, width + textEdit.padding * 2, height + 2 + textEdit.padding * 2)

                    ctx.font = `${textSize}px ${fontToWeb(textEdit.font)}`
                    ctx.strokeStyle = `rgba(${textEdit.strokeColor.r}, ${textEdit.strokeColor.g}, ${textEdit.strokeColor.b}, ${textEdit.strokeColor.a})`
                    ctx.lineWidth = textEdit.borderSize
                    ctx.fillStyle = `rgba(${textEdit.textColor.r}, ${textEdit.textColor.g}, ${textEdit.textColor.b}, ${textEdit.textColor.a})`

                    const totalTextHeight = lines.length * textSize

                    lines.forEach((line, idx) => {
                        const textX = textEdit.textAlignment === TextAlignment.LEFT ? -adjWidth / 2 + textEdit.padding: -adjWidth / 2 + (adjWidth - ctx.measureText(line).width) / 2
                        ctx.strokeText(line, textX, -totalTextHeight / 2 + textSize * (idx + 1))
                        ctx.fillText(line, textX, -totalTextHeight / 2 + textSize * (idx + 1))
                    })

                    ctx.restore()
                    break
                }

                case EditType.IMAGE: {
                    const imageEdit = edit as ImageEdit

                    const drawImage = (img: HTMLImageElement) => {
                        ctx.save()
                        ctx.translate(x, y)
                        ctx.rotate(rotation)
                        ctx.drawImage(img, -width / 2, -height / 2, width, height)
                        ctx.restore()
                    }

                    if (this.imagesCache.get(imageEdit.id) === undefined) {
                        const image = new Image()
                        this.imagesCache.set(imageEdit.id, image)
                        image.onload = function () {
                            drawImage(image)
                        }
                        image.src = imageEdit.img
                    } else {
                        drawImage(this.imagesCache.get(imageEdit.id)!)
                    }

                    break
                }
            }
        }

        // Watermark
        if (editorState.watermark) {
            const xPos = playerState.videoInfo.width - 70
            const yPos = playerState.videoInfo.height - 3
            ctx.strokeStyle = "black"
            ctx.fillStyle = "white"
            ctx.lineWidth = 2
            ctx.font = "13px Impact"
            ctx.strokeText("gifmemes.io", xPos, yPos)
            ctx.fillText("gifmemes.io", xPos, yPos)
        }

        // Draw border for active edit or anchor
        if (currentEdit !== null && isEditVisible(currentEdit, playerState.currentFrame) && !isExporting) {
            const border = anchorIsSelected(currentEdit.moves, editorState.anchorSelected) ?
                getBorder(anchorToMoves(currentEdit.moves as MoveWithAnchor[]), playerState.currentFrame, true) :
                getBorder(currentEdit.moves, playerState.currentFrame)

            const lineColor = `rgb(94, 94, 94)`
            ctx.lineWidth = 1
            ctx.strokeStyle = lineColor

            const corners: Vector[] = [
                {x: border.startX, y: border.startY},
                {x: border.startX + border.width, y: border.startY},
                {x: border.startX + border.width, y: border.startY + border.height},
                {x: border.startX, y: border.startY + border.height}
            ].map((vec) => rotate(vec, border.rotation, {x: border.x, y: border.y}))

            ctx.beginPath()
            corners.forEach(((value, index) => {
                if (index === 0) {
                    ctx.moveTo(value.x, value.y)
                } else {
                    ctx.lineTo(value.x, value.y)
                }
            }))
            ctx.lineTo(corners[0].x, corners[0].y)
            ctx.stroke()

            ctx.fillStyle = "rgb(0,115,255)"
            ctx.lineWidth = 1
            ctx.strokeStyle = "white"

            border.draggers.forEach(corner => {
                ctx.beginPath()
                const {x, y} = rotate({x: corner.x, y: corner.y}, border.rotation, {x: border.x, y: border.y})
                ctx.fillRect(x - corner.width / 2, y - corner.height / 2, corner.width, corner.height)
                ctx.strokeRect(x - corner.width / 2, y - corner.height / 2, corner.width, corner.height)
            })

            // Show animation trace
            if (Array.isArray(currentEdit.moves)) {
                const currentMoveIdx = getCurrentMoveIdx(currentEdit.moves, playerState.currentFrame)
                const TRACE_LENGTH = 20
                const first = Math.max(1, currentMoveIdx - TRACE_LENGTH)

                for (let i = first; i <= currentMoveIdx; i++) {
                    ctx.beginPath()
                    const move = currentEdit.moves[i]
                    const prevMove = currentEdit.moves[i - 1]
                    const opacity = (TRACE_LENGTH - (currentMoveIdx - i)) / TRACE_LENGTH
                    ctx.strokeStyle = `rgba(255,255,255, ${opacity})`
                    ctx.moveTo(prevMove.x, prevMove.y)
                    ctx.lineTo(move.x, move.y)
                    ctx.stroke()
                }
            }
        }

        ctx.restore()
    }

    finishExporting() {
        this.recorder.stopRecording()
    }

    _exportGif() {
        const playerState = this.getCurrentPlayerState()
        const editorState = this.getCurrentEditorState()

        this.dispatch(changeExportState(ExportState.RUNNING, ExportType.GIF))

        const gif = new GIF({
            workers: 2,
            workerScript: "../gif.worker.js",
            quality: 10,
            height: playerState.videoInfo.height + editorState.titleSpace,
            width: playerState.videoInfo.width,
            repeat: 0
        })

        const canvas = document.createElement("canvas")
        const ctx = canvas.getContext("2d")

        for (let i = editorState.startFrame; i < editorState.endFrame; i++) {
            this.drawFrame(i, ctx!, true)
            const delayValue = playerState.decodedFrames![i].frameInfo.delay
            const delay = delayValue === 0 ? 100 : delayValue

            gif.addFrame(ctx!, {copy: true, delay: delay * 10})
        }

        gif.on("finished", (blob) => {
            this.dispatch(changeExportState(ExportState.NONE, ExportType.GIF, blob))
        })

        gif.render()
    }

    _exportWebm() {
        this.recorder.startRecording(
            () => {
                this.dispatch(setPlayerState(
                    {
                        currentFrame: this.getCurrentEditorState().startFrame,
                        playing: true,
                        nextFrameScheduled: false
                    }
                ))
            },
            (blob) => {
                this.dispatch(setPlayerState(
                    {
                        currentFrame: this.getCurrentEditorState().startFrame,
                        playing: false,
                        nextFrameScheduled: false
                    }
                ))
                this.dispatch(changeExportState(ExportState.NONE, ExportType.WEBM, blob))
            }
        )
    }

    _exportVideo() {
        this.dispatch(setPlayerState(
            {
                currentFrame: this.getCurrentEditorState().startFrame,
                playing: false
            }
        ))

        this.canvas.toBlob((blob) => {
            this.dispatch(savePreviewAction(blob!))
        }, 'image/jpeg', 0.95)

        this._exportGif()
        this._exportWebm()
    }
}

