import styled from "styled-components"
import React from "react"
import GoogleImg from "../../img/btn_google_light_normal_ios.svg"
import firebase from "firebase/app"

const GoogleBtn = styled.div`
  display: flex;
  align-items: center;
  background: white;
  color: #444;
  border-radius: 5px;
  box-shadow: 1px 1px 2px 1px rgb(214 214 214);
  white-space: nowrap;
  margin: auto 0;
  cursor:pointer;
  
  &:hover {
    box-shadow: 1px 1px 5px 1px rgb(214 214 214);
  }
  
  & > span {
    display: block;
    vertical-align: middle;
    font-size: 14px;
    font-weight: bold;
    margin: 0 12px 0 7px;
    user-select: none;
    /* Use the Roboto font that is loaded in the <head> */
    font-family: 'Roboto', sans-serif;
  }
`
const GoogleIcon = styled.div`
  background: url(${GoogleImg}) transparent 5px 50% no-repeat;
  display: inline-block;
  vertical-align: middle;
  width: 40px;
  height: 32px;
`

function _login() {
    const auth = firebase.auth
    auth().signInWithPopup(new auth.GoogleAuthProvider()).then(function(result) {
        // TODO show some nice popup
    }).catch(function(error) {

    });
}


export function LoggedOut() {
    return <GoogleBtn onClick={_login}>
        <GoogleIcon/>
        <span>Sign in with Google</span>
    </GoogleBtn>
}