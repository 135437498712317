import styled from "styled-components"
import React from "react"


const Tooltip = styled.div`
  border: 1px solid rgb(33, 33, 33);
  color: rgb(33, 33, 33);
  border-radius: 9px;
  margin-top: 8px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 5px 5px 0px 5px;
  margin-right: 8px;
  cursor: help;
  user-select: none;
  position: relative;
`

const ShortcutsContainer = styled.div`
  font-family: monospace;
  z-index: 5;
  position: absolute;
  top: 32px;
  width: 230px;
  background: white;
  box-shadow: 2px 2px 5px 0 rgba(126, 126, 126, 0.29);
  color: rgb(33, 33, 33);

  & table {
    border-collapse: collapse;
  }

  & table, td, td {
    border: 1px solid #ddd;
  }

  & td {
    padding: 4px;
  }
  
  visibility:hidden;
  opacity:0;
  transition:visibility 0.15s linear,opacity 0.15s linear;
  
  ${Tooltip}: hover & {
    visibility: visible;
    opacity: 1;
  }

`

export function Shortcuts(props: {}) {
    return <Tooltip>
        Shortcuts
        <ShortcutsContainer>
            <table>
                <thead>
                <tr>
                    <th colSpan={2}>Shortcuts</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>A</td>
                    <td>previous frame</td>
                </tr>
                <tr>
                    <td>D</td>
                    <td>next frame</td>
                </tr>
                <tr>
                    <td>ctrl/⌘ + z</td>
                    <td>Undo</td>
                </tr>
                <tr>
                    <td>W</td>
                    <td>Motion track</td>
                </tr>
                <tr>
                    <td>Spacebar</td>
                    <td>Play/Pause</td>
                </tr>
                <tr>
                    <td>alt/⌥ + <br/> click</td>
                    <td>focus motion <br/> tracking anchor</td>
                </tr>
                <tr>
                    <td>Backspace</td>
                    <td>Delete selected edit</td>
                </tr>
                </tbody>
            </table>
        </ShortcutsContainer>
    </Tooltip>
}