export enum EditType {
    TEXT,
    IMAGE
}

export enum TimelineDragType {
    LEFT,
    RIGHT,
    PIN,
    START,
    END
}

export enum AnimationType {
    START,
    INSTANT,
    LINEAR,
}

export enum TextAlignment {
    LEFT,
    CENTER
}

export type Font = "Impact" | "Arial" | "ComicSans"

export interface Position {
    x: number,
    y: number,
    rotation: number,
    width: number,
    height: number,
}

export interface Move extends Position{
    frame: number
}

export interface MoveWithAnchor extends Move {
    anchorPosition: Position
}

export type PositionState = Position | Move[] | MoveWithAnchor[]

export interface Color {
    r: number,
    g: number,
    b: number,
    a: number
}

export interface Edit{
    id: number,
    type: EditType,
    moves: PositionState,
    startFrame: number,
    endFrame: number
}

export interface TextEdit extends Edit {
    text: string,
    font: Font,
    textAlignment: TextAlignment
    borderSize: number,
    textColor: Color,
    strokeColor: Color,
    backgroundColor: Color,
    padding: number,
}

export interface ImageEdit extends Edit {
    img: string
}

export enum ExportState {
    STARTED,
    RUNNING,
    NONE
}

export enum UploadState {
    NONE,
    UPLOADING,
    UPLOADED,
    FAILED
}

export interface EditorState {
    edits: Edit[],
    titleSpace: number,
    startFrame: number,
    endFrame: number,
    active?: number,
    dragType?: TimelineDragType,
    selectedAnimationType: AnimationType,
    anchorSelected: boolean,
    motionTrackingInProgress: boolean,
    autoTrackingInProgress: boolean,
    autoTrackingAverage: {
        average: number,
        n: number
    },
    progress: {
        note: string,
        uploadProgress: UploadState,
        exportProgress: {
            gif: ExportState
            video: ExportState
        },
    },
    exported: {
        exportedVideo?: Blob
        exportedGif?: Blob
        preview?: Blob
    }

    watermark: boolean
}