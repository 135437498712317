import {Edit, EditorState, Font, Move, MoveWithAnchor, Position, PositionState} from "../redux/reducers/editor/types"
import * as R from "ramda"

export function getEditsWithActiveAltered(state: EditorState, changeEdit: (edit: Edit) => Edit) {
    const activeEditIdx = getEditIdxById(state.edits, state.active!)
    const edit = state.edits[activeEditIdx]
    const newEdits = state.edits.slice()
    newEdits[activeEditIdx] = changeEdit(edit)

    return newEdits
}

export function getEditIdxById(edits: Edit[], id: number): number {
    return edits.findIndex(edit => id === edit.id)
}

export function getActiveEdit(editorState: EditorState): Edit | null {
    if (editorState.active === undefined) {
        return null
    }

    const idx = getEditIdxById(editorState.edits, editorState.active)
    if (idx === -1) {
        return null
    } else {
        return editorState.edits[idx]
    }
}

export function getCurrentMoveIdx(moves: Move[] | Position, currentFrame: number): number {
    if (!Array.isArray(moves)) return 0

    let idx = moves.length - 1
    // Binary search would be better
    while (moves[idx].frame > currentFrame && idx > 0) {
        idx--
    }
    return idx
}

export function editCurrentMoves(oldMoves: Move[] | Position, x: number, y: number, rotation: number, width: number, height: number, currentFrame: number, anchor?: Position): Move[] | Position {
    if (Array.isArray(oldMoves)) {
        const newMove =
            anchor ?
                {x, y, rotation, width, height, frame: currentFrame, anchorPosition: anchor} :
                {x, y, rotation, width, height, frame: currentFrame}
        const idx = getCurrentMoveIdx(oldMoves, currentFrame)

        if (oldMoves[idx].frame !== currentFrame) {
            return addMovePoint(oldMoves, currentFrame, x, y, rotation, height, width, anchor)
        } else {
            return R.adjust(idx, (move: Move) => {
                return {...newMove, frame: move.frame}
            }, oldMoves)
        }
    } else {
        if (anchor) throw Error("Illegal state")

        return {
            rotation,
            x,
            y,
            width,
            height
        }
    }
}

export function getCurrentSizeAndPosition(moves: Position | Move[], frame: number): { x: number, y: number, width: number, height: number, rotation: number, startX: number, startY: number } {
    let x, y, rotation, width, height
    if (Array.isArray(moves)) {
        let idx = getCurrentMoveIdx(moves, frame)
        width = moves[idx].width
        height = moves[idx].height
        x = moves[idx].x
        y = moves[idx].y
        rotation = moves[idx].rotation
    } else {
        width = moves.width
        height = moves.height
        x = moves.x
        y = moves.y
        rotation = moves.rotation
    }

    const startX = Math.round(x - width / 2)
    const startY = Math.round(y - height / 2)

    return {
        x,
        y,
        startX,
        startY,
        width,
        height,
        rotation
    }
}

export function getCurrentPosition(edit: Edit, currentFrame: number): Position {
    if (Array.isArray(edit.moves)) {
        return edit.moves[getCurrentMoveIdx(edit.moves, currentFrame)]
    } else {
        return edit.moves
    }
}

export function getMaxId(edits: Edit[]): number {
    let maxId = 0
    edits.forEach(edit => {
        if (maxId < edit.id) {
            maxId = edit.id
        }
    })
    return maxId
}

export function fontToWeb(font: Font): string {
    switch (font) {
        case "Arial":
            return "Arial Black, Gadget, sans-serif"
        case "ComicSans":
            return "Comic Sans MS"
        case "Impact":
            return "Impact, Charcoal, sans-serif"
    }
}

export function addMovePoint(oldMoves: Move[] | MoveWithAnchor[], frame: number, x: number, y: number, rotation: number, height: number, width: number, anchor?: Position): Move[] {
    const currentMoveIdx = getCurrentMoveIdx(oldMoves, frame)
    console.log("Entered add move point " + oldMoves)

    oldMoves.forEach(value => {
        console.log(value)
    })

    const newIdx = (oldMoves[currentMoveIdx].frame > frame) ? currentMoveIdx : currentMoveIdx + 1

    const newMove: Move = {
        frame,
        x, y,
        rotation: rotation,
        width,
        height
    }

    if (anchor) {
        const newMoveWithAnchor: MoveWithAnchor = {
            ...newMove,
            anchorPosition: anchor
        }

        console.log("adding " + newMoveWithAnchor)
        return R.insert(newIdx, newMoveWithAnchor, oldMoves)
    }
    console.log("adding " + newMove)
    return R.insert(newIdx, newMove, oldMoves)
}

export function addMovePointWithAnchor(oldMoves: MoveWithAnchor[], newMove: MoveWithAnchor): MoveWithAnchor[] {
    const currentMoveIdx = getCurrentMoveIdx(oldMoves, newMove.frame)
    const newIdx = (oldMoves[currentMoveIdx].frame > newMove.frame) ? currentMoveIdx : currentMoveIdx + 1
    return R.insert(newIdx, newMove, oldMoves)
}

export function readableMove(move: Move): Move {
    return {
        frame: move.frame,
        x: Math.round(move.x),
        y: Math.round(move.y),
        width: Math.round(move.width),
        height: Math.round(move.height),
        rotation: Math.round(move.rotation / (2 * Math.PI) * 360)
    }
}

export function getRealDelay(delayValue: number) {
    if (delayValue === 0) {
        return 100
    } else {
        return delayValue * 10
    }
}

export function hasAnchor(position: PositionState) {
    return Array.isArray(position) && "anchorPosition" in position[0]
}

export function anchorIsSelected(position: PositionState, anchorSelected: boolean) {
    return hasAnchor(position) && anchorSelected
}

export function anchorToMoves(movesWithAnchor: MoveWithAnchor[]): Move[] {
    return movesWithAnchor.map(value => {
        return {...value.anchorPosition, frame: value.frame}
    })
}

export function getDefaultAnchor(position: Position): Position {
    return {
        x: position.x + Math.round(position.width / 2 + 50 / 2) + 15,
        y: position.y,
        rotation: 0,
        width: 50,
        height: 50
    }
}

