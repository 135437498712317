import * as React from "react"
import {TextInput} from "./EditPanel"
import {Color, Font, TextAlignment, TextEdit} from "../../../redux/reducers/editor/types"
import alignLeftImg from "../../../img/format_align_left-24px.svg"
import alignCenterImg from "../../../img/format_align_center-24px.svg"
import styled from "styled-components"
import {useDispatch, useSelector} from "react-redux"
import {RootState} from "../../../redux/reducers"
import {getActiveEdit} from "../../../utils/StateHelpers"
import {editText, saveHistory} from "../../../redux/actions/editorActions"
import {ColorPicker} from "./ColorPicker"
import {AnimationControls} from "./animation/AnimationControls"

const Container = styled.div<{ collapsed: boolean }>`
  display: flex;
  width: ${props => props.collapsed ? "280px" : "560px"};
  transition: width 0.3s;
  overflow-x: hidden;
  overflow-y: clip;
`

const AlignmentButton = styled.button<{ active: boolean }>`
  border: none;
  padding: 0;
  cursor: pointer;
  background-color: ${props => props.active ? "#bdbdbd" : "#eaeaea"};

  &:focus {
    outline: 0;
  }
`

const Table = styled.table`
  flex: 1;

  tr td:nth-child(1) {
    text-align: right;
    padding-right: 12px;
    color: #545454;
    font-size: 14px;
  }
`

const AnimateToggle = styled.div`
  display: flex;
  cursor: pointer;

  & label {
    cursor: pointer;
    font-size: 1.3em;
    font-weight: bold;
  }

  & input {
    margin-left: 6px;
    cursor: pointer;
  }
`

export function TextEditEditor(props: { toggleAnimate: () => void }) {
    const dispatch = useDispatch()
    const _editText = (params: Partial<TextEdit & { textSize: number }>) => dispatch(editText(params))

    const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => _editText({text: e.target.value})
    const handleTextAlignmentChange = (alignment: TextAlignment) => _editText({textAlignment: alignment})
    const handleTextColorChange = (textColor: Color) => _editText({textColor})
    const handleStrokeColorChange = (strokeColor: Color) => _editText({strokeColor})
    const handleBorderChange = (e: React.ChangeEvent<HTMLInputElement>) => _editText({borderSize: parseInt(e.target.value)})
    const handleFontChange = (e: React.ChangeEvent<HTMLSelectElement>) => _editText({font: e.target.value as Font})
    const handleBackgroundColorChange = (backgroundColor: Color) => _editText({backgroundColor})
    const handlePaddingChange = (e: React.ChangeEvent<HTMLInputElement>) => _editText({padding: parseInt(e.target.value)})

    const edit = useSelector((state: RootState) => getActiveEdit(state.editor)) as TextEdit
    const moves = edit.moves
    const isAnimated = Array.isArray(moves)


    return (
        <Container collapsed={!isAnimated}>
            <Table>
                <tbody>
                <tr>
                    <td colSpan={2}>
                        <AnimateToggle onClick={props.toggleAnimate}>
                            <label htmlFor="male">Animated: </label>
                            <input type="checkbox" name="animate" id="animate" checked={isAnimated} readOnly/>
                        </AnimateToggle>
                        <hr/>
                    </td>
                </tr>

                <tr>
                    <td colSpan={2} style={{textAlign: "left"}}><TextInput type="text" style={{width: "100%"}}
                                                                           value={edit.text}
                                                                           onChange={handleTextChange}/></td>
                </tr>

                <tr>
                    <td>
                        Alignment
                    </td>
                    <td>
                        <AlignmentButton
                            onClick={() => handleTextAlignmentChange(TextAlignment.LEFT)}
                            active={edit.textAlignment === TextAlignment.LEFT}>
                            <img alt={""} src={alignLeftImg}/>
                        </AlignmentButton>
                        <AlignmentButton
                            onClick={() => handleTextAlignmentChange(TextAlignment.CENTER)}
                            active={edit.textAlignment === TextAlignment.CENTER}>
                            <img alt={""} src={alignCenterImg}/>
                        </AlignmentButton>
                    </td>
                </tr>

                <tr>
                    <td>
                        Font
                    </td>
                    <td>
                        <select value={edit.font} onChange={handleFontChange}>
                            <option value="Arial">Arial</option>
                            <option value="ComicSans">Comic Sans</option>
                            <option value="Impact">Impact</option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <td>
                        Text color
                    </td>
                    <td>
                        <ColorPicker
                            color={edit.textColor}
                            onSetColor={handleTextColorChange}
                        />
                    </td>
                </tr>
                <tr>
                    <td>
                        Border size
                    </td>
                    <td>
                        <input type="range" min="0" max="10" value={edit.borderSize}
                               onChange={handleBorderChange} onMouseDownCapture={() => dispatch(saveHistory())}/>
                    </td>
                </tr>
                <tr>
                    <td>
                        Border color
                    </td>
                    <td>
                        <ColorPicker
                            color={edit.strokeColor}
                            onSetColor={handleStrokeColorChange}
                        />
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        <hr/>
                    </td>
                </tr>
                <tr>
                    <td>
                        Background color
                    </td>
                    <td>
                        <ColorPicker
                            color={edit.backgroundColor}
                            onSetColor={handleBackgroundColorChange}
                        />
                    </td>
                </tr>

                <tr>
                    <td>
                        Background size
                    </td>
                    <td>
                        <input type="range" min="0" max="30" value={edit.padding}
                               onChange={handlePaddingChange} onMouseDownCapture={() => dispatch(saveHistory())}/>
                    </td>
                </tr>
                </tbody>
            </Table>
            <AnimationControls/>
        </Container>
    )

}