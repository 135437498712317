import {templatesDb} from "../../firebase/firebaseHelper"
import React, {useEffect, useState} from "react"
import {Edit} from "../../redux/reducers/editor/types"
import {Template} from "./Template"
import styled from "styled-components"
import { Loader } from "../../styles/sharedComponents"

interface TemplateData {
    edits: Edit[],
    id: string,
    title: string
    userId: string,
}
interface IProps {
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`

let cache: TemplateData[] | undefined = undefined

function _loadTemplates(setTemplates: (templates: TemplateData[]) => void) {
    if (cache) {
        setTemplates(cache)
        return
    }

    templatesDb.get().then((snapshot) => {
        const results: TemplateData[] = []
        snapshot.forEach((res) => {
            templatesDb.doc(res.data().uid).collection('userTemplates').get().then((templatesSnapshot) => {
                templatesSnapshot.forEach(
                    (template) => {
                        results.push(template.data() as TemplateData)
                    })

                cache = results
                setTemplates(results)
            })
        })
    })
}



export function Templates(props: IProps) {
    const [templates, setTemplates] = useState<TemplateData[]>([])

    useEffect(() => {
        _loadTemplates(setTemplates)
    }, [])

    const items = templates.map((template) => {
        return <Template loadTemplates={() => _loadTemplates(setTemplates)} authorId={template.userId} templateId={template.id} title={template.title} key={template.id}/>
    })

    return <Container>
        {items.length !== 0 && items}
        {items.length === 0 && <Loader style={{marginTop:"50px"}}/>}
    </Container>
}