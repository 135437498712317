import {GifReader} from "omggif"
import {SimpleDecodedFrame} from "./types"

export const ERROR = 'error'

onmessage = function (e) {
    if (e.data.type !== "gifmemes") {
        return
    }

    try {
        const frames: SimpleDecodedFrame[] = []
        const gifReader = new GifReader(e.data.gifData)

        // TODO Could be parallelized
        for (let i = 0; i < gifReader.numFrames(); i++) {
            let pixels
            if (i === 0) {
                // @ts-ignore
                pixels = new Uint8ClampedArray(gifReader.width * gifReader.height * 4)
            } else {
                // Copy previous pixels to get rid of compression artifacts
                pixels = frames[i - 1].pixels.slice(0)
            }

            const frameInfo = gifReader.frameInfo(i)
            // The types are wrong
            // @ts-ignore
            gifReader.decodeAndBlitFrameRGBA(i, pixels)

            frames[i] = {
                frameInfo,
                pixels,
            }
        }

        // @ts-ignore
        postMessage({
            frames,
            gifInfo: {
                width: gifReader.frameInfo(0).width,
                height: gifReader.frameInfo(0).height,
                loopCnt: gifReader.loopCount()
            }
        })
    } catch (e) {
        // @ts-ignore
        postMessage(ERROR);
    }


}

